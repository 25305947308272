<template>
  <div class="identifications-infos">
    <div class="begin">
      <div class="title font-medium uppercase">{{ $t('identificationInformation') }}</div><br>
      <div class="infos">
        <div class="question">{{ $t('registrationNumber') }}</div>
        <div class="answer text-lg">{{ isIdentification.matricule }}</div>
      </div><br>
      <div class="infos">
        <div class="question">{{ $t('completeName') }}</div>
        <div class="answer text-lg">{{ isIdentification.name }}</div>
      </div><br>
      <div class="infos">
        <div class="question">{{ $t('phone') }}</div>
        <div class="answer text-lg">{{ isIdentification.phone }}</div>
      </div><br>
      <div class="infos">
        <div class="question">{{ $t('address') }}</div>
        <div class="answer text-lg leading-relaxed w-3/4">{{ isIdentification.address }}</div>
      </div><br>
      <div class="infos">
        <div class="question">{{ $t('birthdayDate') }}</div>
        <div class="answer text-lg">{{ new Date(isIdentification.birthday).toLocaleDateString('FR') }}</div>
      </div><br>
    </div>
  </div>
</template>

<script>
export default {
  name: 'identification-infos',
  props: {
    isIdentification: Object
  },
  data () {
    return {
      options: { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "src/assets/styles/sass/variables";
  .title {
    color: $team_blue;
  }
  .question {
    color: #909090;
  }
</style>
