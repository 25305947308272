<template>
  <div class="assigned-site">
    <div class="begin">
      <div class="title font-medium uppercase">{{ $t('assignedSite') }}</div><br>
      <div class="society">
        <div v-if="siteInfo !== null" class="society-content flex items-center justify-between">
          <div class="ets flex items-center w-3/5 cursor-pointer" @click="openDetailsAffectation()">
            <div class="ets-icon mr-4">
              <icon :data="icons.ets" original width="50" height="50"/>
            </div>
            <div class="infos">
              <div class="name font-semibold text-xl">{{ siteInfo.client + ' (' + siteInfo.name + ')' }}</div>
              <div class="location flex">
                <div class="locate-icon mr-2"><icon :data="icons.locate" original width="10" height="10"/></div>
                <div class="locate-name">{{ siteInfo.address }}</div>
              </div>
            </div>
          </div>
          <div class="show-details flex items-center justify-end w-1/5">
            <div class="status mr-4">{{ $t('status') }}:</div>
            <div class="agent-status-action">
              <div
                v-if="agentsDetails.status.toLowerCase() === 'active'"
                class="status-action rounded-full px-4 py-1 w-fit text-center"
                :style="{ color: '#324971', backgroundColor: 'rgba(50, 73, 113, 0.1)' }"
              >
                {{ $t('active') }}
              </div>
              <div
                v-if="agentsDetails.status.toLowerCase() === 'inservice'"
                class="status-action rounded-full px-4 py-1 w-fit text-center"
                :style="{ color: '#009900', backgroundColor: 'rgba(0, 153, 0, 0.1)' }"
              >
                {{ $t('inService') }}
              </div>
              <div
                v-if="agentsDetails.status.toLowerCase() === 'outservice'"
                class="status-action rounded-full px-4 py-1 w-fit text-center"
                :style="{ color: '#FBA705', backgroundColor: 'rgba(251, 167, 5, 0.1)' }"
              >
                {{ $t('resting') }}
              </div>
              <div
                v-if="agentsDetails.status.toLowerCase() === 'onvacation'"
                class="status-action rounded-full px-4 py-1 w-fit text-center"
                :style="{ color: '#94A4C4', backgroundColor: 'rgba(148, 164, 196, 0.1)' }"
              >
                {{ $t('onLeave') }}
              </div>
              <div
                v-if="agentsDetails.status.toLowerCase() === 'suspended'"
                class="status-action rounded-full px-4 py-1 w-fit text-center"
                :style="{ color: '#FB0505', backgroundColor: 'rgba(251, 5, 5, 0.1)' }"
              >
                {{ $t('suspended') }}
              </div>
              <div
                v-if="agentsDetails.status.toLowerCase() === 'reserved'"
                class="status-action rounded-full px-4 py-1 w-fit text-center"
                :style="{ color: '#324971', backgroundColor: 'rgba(50, 73, 113, 0.1)' }"
              >
                {{ $t('reservist') }}
              </div>
            </div>
            <div class="icons-edit cursor-pointer ml-4" @click="openChangeAffectation">
              <icon :data="icons.edition" original width="15" height="15" />
            </div>
          </div>
        </div>
        <div v-else>{{ $t('noSiteAssigned') }}</div>
      </div><br>
      <div class="some-infos">
        <div class="infos flex items-center">
          <div class="question w-1/4">{{ $t('takenService') }} :</div>
          <div class="answer w-3/4">
            <div v-if="agentsDetails.takeServiceId === ''">-</div>
            <div v-else>{{ dating }}</div>
          </div>
        </div><br>
      </div>
    </div>
    <!-- CHANGE AGENT DESIGNATED SITE POPUP -->
    <change-designated-site
      v-if="isChangeDesignatedSite"
      :actual-agent-site="allSiteInfo"
      :actual-agent="agentsDetails"
      @closeChangeAffectation="closeChangeAffectation"
      @openSuccess="openSuccess"
    />
    <!-- SUCCESS POPUP -->
    <success-popup-base
      v-if="isSuccess"
      :success-description="$t('successChangeAffectation')"
      @closeSuccess="closeSuccess"
    />
    <!-- DESIGNATED SITE DETAILS POPUP -->
    <designated-site-info
      v-if="isDetails && agentsDetails !== null && allSiteInfo !== null"
      :affectation-info="siteInfo"
      :affected-site-id="allSiteInfo.uid"
      :agent-id="agentsDetails.uid"
      @closeDetailsAffectation="closeDetailsAffectation"
    ></designated-site-info>
  </div>
</template>

<script>
import ets from '../../../assets/icons/ic_society.svg'
import locate from '../../../assets/icons/ic_location.svg'
import edition from '../../../assets/icons/ic_pencil.svg'
import { collection, query, where, getDocs } from 'firebase/firestore'
import { firestore } from '../../../main'
import * as moment from 'moment'
import 'moment/locale/fr'
import ChangeDesignatedSite from '@/components/helper/agents/change-designated-site'
import SuccessPopupBase from '@/components/helper/add/success-popup-base'
import DesignatedSiteInfo from '@/components/helper/agents/designated-site-info'
export default {
  name: 'assigned-site',
  components: { DesignatedSiteInfo, SuccessPopupBase, ChangeDesignatedSite },
  props: {
    agentsDetails: Object
  },
  data () {
    return {
      icons: { ets, locate, edition },
      isSiteAgents: false,
      allDesignation: [],
      siteInfo: null,
      noSite: true,
      dating: null,
      allSiteInfo: null,
      isChangeDesignatedSite: false,
      isSuccess: false,
      isDetails: false
    }
  },
  created () {
    this.getAllDesignation()
    this.getTakenServiceInfo()
    // console.log(this.agentsDetails)
  },
  methods: {
    openDetailsAffectation () { // OPEN AFFECTATION DETAILS POPUP
      this.isDetails = true
    },
    closeDetailsAffectation (value) { // CLOSE AFFECTATION DETAILS POPUP
      this.isDetails = value
    },
    openSuccess (value) { // OPEN SUCCESS POPUP
      this.isSuccess = value
    },
    closeSuccess (value) { // CLOSE SUCCESS POPUP
      this.isSuccess = value
    },
    closeChangeAffectation (value) { // CLOSE AGENT CHANGE SITE AFFECTATION POPUP
      this.isChangeDesignatedSite = value
    },
    openChangeAffectation () { // OPEN AGENT CHANGE SITE AFFECTATION POPUP
      this.isChangeDesignatedSite = true
    },
    async getTakenServiceInfo () { // TAKEN SERVICE INFORMATION
      const q = query(collection(firestore, 'takenService'),
        where('uid', '==', this.agentsDetails.takeServiceId))

      const querySnapshot = await getDocs(q)
      querySnapshot.forEach((doc) => {
        // console.log(doc.id, ' => ', doc.data())
        this.takeService = doc.data()
        this.dating = moment(doc.data().dateUp).fromNow()
      })
    },
    async getDesignateSite () {
      const table = []
      for (let i = 0; i < this.allDesignation.length; i++) {
        for (let j = 0; j < this.allDesignation[i].designatedAgents.length; j++) {
          table.push(this.allDesignation[i].designatedAgents[j])
          if (this.allDesignation[i].designatedAgents[j].agent === this.agentsDetails.uid) {
            const siteId = this.allDesignation[i].siteId
            const q = query(collection(firestore, 'sites'),
              where('uid', '==', siteId)
            )
            const querySnapshot = await getDocs(q)
            querySnapshot.forEach((doc) => {
              // console.log(doc.id, ' => ', doc.data())
              this.allSiteInfo = doc.data()
              this.siteInfo = doc.data().identification
            })
          }
        }
      }
    },
    async getAllDesignation () { // GET All AGENT DESIGNATION
      const querySnapshot = await getDocs(collection(firestore, 'designation'))
      querySnapshot.forEach((doc) => {
        // console.log(doc.id, ' => ', doc.data())
        this.allDesignation.push(doc.data())
        this.getDesignateSite()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "src/assets/styles/sass/variables";
  .svg-fill {
    fill: transparent;
  }
  .title {
    color: $team_blue;
  }
  .locate-name {
    color: #606060;
  }
  .status, .question {
    color: #909090;
  }
  .icons-edit::v-deep {
    .svg-fill {
      path {
        fill: #909090;
      }
    }
  }
</style>
