<template>
  <div class="agent-stats">
    <div class="begin flex items-center">
      <div class="start bg-white mr-3 w-1/2 mb-6">
        <div class="number text-5xl font-medium px-8 pt-8">{{ agentsStats?.numberAffected }}</div>
        <div class="title px-8 text-xl font-medium mt-6">{{ $t('affectedEquipments') }}</div>
        <div class="flex items-center justify-between px-8">
          <div class="show text-lg cursor-pointer" @click="showAffected">{{ $t('showAll') }}</div>
          <div class="icons"><icon :data="icons.equipment" original width="100" height="75"/></div>
        </div>
      </div>
      <div class="start bg-white ml-3 w-1/2 mb-6">
        <div class="number text-5xl font-medium px-8 pt-8">{{ requestNumber }}</div>
        <div class="title px-8 text-xl font-medium mt-6">{{ $t('requests') }}</div>
        <div class="flex items-center justify-between px-8">
          <div class="show text-lg cursor-pointer" @click="showRequest">{{ $t('showAll') }}</div>
          <div class="icons"><icon :data="icons.hands" original width="100" height="75"/></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import hands from '../../../assets/icons/ic_hand_number.svg'
import equipment from '../../../assets/icons/ic_equipment_number.svg'
export default {
  name: 'agent-stats',
  props: {
    agentsStats: Object,
    requestNumber: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      icons: { hands, equipment }
    }
  },
  methods: {
    showRequest () {
      this.$emit('showRequests', true)
    },
    showAffected () {
      this.$emit('showAffectedEquipment', true)
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "src/assets/styles/sass/variables";
  .show {
    color: $team_checkbox;
  }
  .svg-fill {
    fill: transparent;
  }
  .start {
    filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.1));
    &:first-child {
      background: rgba(38, 61, 100, .1);
      .number {
        color: $team_checkbox;
      }
    }
    &:last-child {
      background: rgba(81, 56, 238, .1);
      .number {
        color: $team_indigo;
      }
    }
  }
</style>
