<template>
  <div class="add-contractual">
    <div class="begin">
      <div class="form w-full">
        <div class="form-bloc flex items-center w-full">
          <div class="login-form mr-3 w-1/3">
            <div class="label mb-2">{{ $t('function') }}*</div>
            <div class="input-bloc">
              <Multiselect
                v-model="agentFunction"
                :options="optionsAgentFunction"
                label="name"
                valueProp="value"
                placeholder="Agent"
                :classes="{
                    container: 'ipt relative mx-auto w-full flex items-center justify-end box-border cursor-pointer border rounded p-5 leading-snug outline-none',
                    clear: 'hidden',
                    optionSelected: 'text-black bg-none',
                    optionSelectedPointed: 'text-gray-800 bg-gray-100'
                  }"
                @select="sendAgentFunction"
              />
            </div>
          </div>
          <div class="login-form mx-3 w-1/3">
            <div class="label mb-2">{{ $t('hiringDate') }}*</div>
            <div class="input-bloc">
              <v-date-picker class="inline-block birth h-full w-full z-50" :max-date="new Date()" v-model="date" @keyup="sendDate" @click="sendDate">
                <template v-slot="{ inputValue, inputEvents, togglePopover }">
                  <div class="flex items-center">
                    <input
                      :value="inputValue"
                      placeholder="12/08/1993"
                      class="ipt w-full p-4 appearance-none border border-r-0 rounded-l focus:outline-none"
                      v-on="inputEvents"
                    />
                    <button
                      class="p-4 border border-l-0 rounded-r focus:outline-none"
                      @click="togglePopover()"
                    >
                      <icon :data="icons.calendar" original width="15" height="15"/>
                    </button>
                  </div>
                </template>
              </v-date-picker>
            </div>
          </div>
          <div class="login-form ml-3 w-1/3">
            <div class="label mb-2">{{ $t('contractType') }}*</div>
            <div class="input-bloc">
              <Multiselect
                v-model="type"
                :options="optionsType"
                placeholder="CDD"
                :classes="{
                    container: 'ipt relative mx-auto w-full flex items-center justify-end box-border cursor-pointer border rounded p-5 leading-snug outline-none',
                    clear: 'hidden',
                    optionSelected: 'text-black bg-none',
                    optionSelectedPointed: 'text-gray-800 bg-gray-100'
                  }"
                @select="sendType"
              />
            </div>
          </div>
        </div><br>
        <div class="form-bloc flex items-center w-full">
          <div class="login-form mr-3 w-1/3">
            <div class="label mb-2">{{ $t('contractTerm') }}</div>
            <div class="input-bloc w-full">
              <v-date-picker class="inline-block birth h-full w-full z-50" :min-date="new Date()" v-model="term" @keyup="sendTerm" @click="sendTerm">
                <template v-slot="{ inputValue, inputEvents, togglePopover }">
                  <div class="flex items-center">
                    <input
                      :value="inputValue"
                      placeholder="12/08/1993"
                      class="ipt w-full p-4 appearance-none border border-r-0 rounded-l focus:outline-none"
                      v-on="inputEvents"
                    />
                    <button
                      class="p-4 border border-l-0 rounded-r focus:outline-none"
                      @click="togglePopover()"
                    >
                      <icon :data="icons.calendar" original width="15" height="15"/>
                    </button>
                  </div>
                </template>
              </v-date-picker>
            </div>
          </div>
          <div class="login-form mx-3 w-1/3">
            <div class="label mb-2">{{ $t('salary') }}*</div>
            <div class="input-bloc">
              <input
                v-model="salary"
                type="number"
                placeholder="125 000"
                class="ipt p-4 w-full border rounded"
                @keypress="isNumberOnly"
                @keyup="sendSalary"
              >
            </div>
          </div>
          <div v-if="agentFunction === 'supervisor'" class="login-form ml-3 w-1/3">
            <div class="label mb-2">{{ $t('zoneSupervisor') }}*</div>
            <div class="input-bloc">
              <Multiselect
                v-model="zone"
                :options="zoneList"
                placeholder="Zone nord"
                label="zone"
                valueProp="uid"
                :classes="{
                    container: 'ipt relative mx-auto w-full flex items-center justify-end box-border cursor-pointer border rounded p-5 leading-snug outline-none',
                    clear: 'hidden',
                    optionSelected: 'text-black bg-none',
                    optionSelectedPointed: 'text-gray-800 bg-gray-100'
                  }"
                @select="sendZone"
              />
            </div>
          </div>
        </div><br>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from '@vueform/multiselect'
import calendar from '../../../assets/icons/ic_calendar.svg'
import { collection, getDocs } from 'firebase/firestore'
import { firestore } from '../../../main'
export default {
  name: 'add-contractual',
  components: { Multiselect },
  props: {
    isAgentDetails: Object
  },
  data () {
    return {
      icons: { calendar },
      agentFunction: '',
      date: new Date(),
      type: '',
      term: '',
      salary: null,
      optionsAgentFunction: [
        { name: this.$t('agent'), value: 'agent' }, { name: this.$t('supervisors'), value: 'supervisor' }
      ],
      optionsType: ['CDD', 'CDI', 'Prestation', 'Consultant', 'Autre'],
      zone: '',
      zoneList: []
    }
  },
  created () {
    // console.log(this.isAgentDetails)
    this.getAllZone()
  },
  mounted () {
    if (Object.keys(this.isAgentDetails).length !== 0) {
      this.agentFunction = this.isAgentDetails.agentFunction
      this.date = new Date(this.isAgentDetails.hiringDate)
      this.type = this.isAgentDetails.contractType
      this.term = new Date(this.isAgentDetails.contractTerm)
      this.salary = this.isAgentDetails.salary

      this.$emit('sendAgentFunction', this.agentFunction)
      this.$emit('sendDate', this.date)
      this.$emit('sendType', this.type)
      this.$emit('sendTerm', this.term)
      this.$emit('sendSalary', this.salary)
    }
  },
  methods: {
    async getAllZone () {
      const querySnapshot = await getDocs(collection(firestore, 'zone'))
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        // console.log(doc.id, ' => ', doc.data())
        this.zoneList.push(doc.data())
      })
    },
    sendZone () {
      console.log(this.zone)
      this.$emit('sendSupervisorZone', this.zone)
    },
    sendAgentFunction () {
      this.$emit('sendAgentFunction', this.agentFunction)
    },
    sendDate () {
      this.$emit('sendDate', this.date)
      // console.log(this.date)
    },
    sendType () {
      this.$emit('sendType', this.type)
    },
    sendTerm () {
      this.$emit('sendTerm', this.term)
      console.log(new Date(this.term))
    },
    sendSalary () {
      this.$emit('sendSalary', this.salary)
    },
    isNumberOnly ($event) {
      const keyCode = ($event.keyCode ? $event.keyCode : $event.which)
      // alert(keyCode)
      if ((keyCode < 42 || keyCode > 57) && keyCode !== 46) { // 46 is dot
        $event.preventDefault()
      }
    }
  }
}
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
<style lang="scss" scoped>
  @import "src/assets/styles/sass/variables";
  .label {
    color: $team_label;
  }
  .input-bloc::v-deep {
    .vc-container {
      font-family: $font-default !important;
      .vc-day-content {
        font-weight: 400 !important;
      }
    }
  }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
</style>
