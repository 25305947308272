<template>
  <div class="affected-equipment">
    <div class="begin">
      <div class="header-table flex items-center p-4 bg-white rounded-xl font-medium text-lg">
        <div class="action w-2/3 mr-4">{{ $t('completeName') }}</div>
        <div class="action w-1/3 mr-4">{{ $t('affectedQuantity') }}</div>
        <div class="action w-1/3 mr-4">{{ $t('date') }}</div>
      </div>
      <div class="header-content">
        <div class="loading" :class="{ 'not_empty': loading === false }">
          <loading
            :active="loading"
            :can-cancel="false"
            :on-cancel="onCancel"
            :is-full-page="fullPage"
            background-color="#FFFFFF"
            color="#324971"
            loader="dots"
          ></loading>
        </div>
        <div
          v-if="nothing"
          class="no-student text-center text-xl text-gray-500 py-32"
        >
          {{ $t('noAffectedEquipmentAdded') }}
        </div>
        <affected-lists
          v-for="(item, index) in allEquipments"
          :key="index"
          :affected-equipments="item"
        />
      </div>
      <div class="affected-button mt-12">
        <button-base
          :label="'+ ' + $t('affectEquipmentButton')"
          fontcolor="#5138EE"
          background="#FFFFFF"
          @click="openAddAffect"
        />
      </div>
    </div>
    <add-affect-equipment
      v-if="isAddAffect"
      :details-agent="detailsAgents"
      @closeAddAffected="closeAddAffected"
      @changeEquipment="changeEquipment"
    />
  </div>
</template>

<script>
import AffectedLists from './affected-lists'
import ButtonBase from '../add/button-base'
import AddAffectEquipment from './add-affect-equipment'
import { collection, getDocs, query, where, orderBy } from 'firebase/firestore'
import { firestore } from '../../../main'
import Loading from 'vue3-loading-overlay'
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css'
export default {
  name: 'affected-equipment',
  components: { Loading, AddAffectEquipment, ButtonBase, AffectedLists },
  props: {
    detailsAgents: Object
  },
  data () {
    return {
      allEquipments: [],
      isAddAffect: false,
      loading: true,
      fullPage: false,
      nothing: false
    }
  },
  created () {
    this.getAgentAffectedEquipment()
  },
  methods: {
    async getAgentAffectedEquipment () { // GET ALL AFFECTED EQUIPMENTS PER AGENT
      if (this.detailsAgents.haveEquipment === false) {
        this.loading = false
        this.nothing = true
      } else {
        this.loading = false
        this.nothing = false
      }
      const q = query(collection(firestore, 'affected'),
        where('agentId', '==', this.detailsAgents.uid),
        orderBy('createdAt', 'desc')
      )
      const querySnapshot = await getDocs(q)
      querySnapshot.forEach((doc) => {
        // console.log(doc.id, ' => ', doc.data())
        this.allEquipments.push(doc.data())
      })
    },
    changeEquipment (val) { // ADD NEW EQUIPMENT AFFECTED
      this.allEquipments.push(val)
    },
    closeAddAffected (val) {
      this.isAddAffect = val
    },
    openAddAffect () {
      this.isAddAffect = true
    },
    onCancel () {
      console.log('User cancelled the loader.')
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "src/assets/styles/sass/variables";
  .header-table {
    background: $team_gray_popup;
  }
  .action {
    color: $team_blue;
  }
  .loading {
    position: relative;
    height: 10vh;
  }
  .not_empty {
    height: auto;
  }
  .affected-button::v-deep {
    .button-base {
      text-align: center;
      text-align: -moz-center;
      text-align: -webkit-center;
      .page-button-real {
        width: 50%;
        border: 1px dashed $team_indigo;
        h4 {
          font-size: 1.25rem;
        }
      }
    }
  }
</style>
