<template>
  <div class="affected-lists">
    <div class="begin mt-1">
      <div class="header-table flex items-center p-4 bg-white text-lg border-b">
        <div class="action w-2/3 mr-4 flex items-center">
          <div
            class="picture rounded-lg w-24 h-16 bg-no-repeat bg-cover"
            v-bind:style="{ backgroundImage: 'url(' + affected.picture + ')' }"
          ></div>
          <div class="name ml-4">
            <div class="title text-black font-medium">{{ affected.name }}</div>
          </div>
        </div>
        <div class="action quantity w-1/3 mr-4 text-center">{{ affectedEquipments.quantity }}</div>
        <div class="action date w-1/3 mr-4 capitalize">
          <div v-if="affectedEquipments.affectedDate !== ''">{{ new Date(affectedEquipments.affectedDate).toLocaleDateString('FR', options) }}</div>
          <div v-else>{{ new Date(affectedEquipments.createdAt).toLocaleDateString('FR', options) }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { collection, getDocs, query, where } from 'firebase/firestore'
import { firestore } from '../../../main'
export default {
  name: 'affected-lists',
  props: {
    affectedEquipments: {
      type: Object,
      default: function () {
        return {
        }
      }
    }
  },
  created () {
    this.getAgentAffectedEquipmentInformation()
  },
  data () {
    return {
      affected: {},
      options: { year: 'numeric', month: 'short', day: '2-digit' }
    }
  },
  methods: {
    async getAgentAffectedEquipmentInformation () { // GET ALL AFFECTED EQUIPMENTS PER AGENT
      const q = query(collection(firestore, 'equipments'), where('uid', '==', this.affectedEquipments.equipmentId))
      const querySnapshot = await getDocs(q)
      querySnapshot.forEach((doc) => {
        // console.log(doc.id, ' => ', doc.data())
        this.affected = doc.data()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "src/assets/styles/sass/variables";
  .picture {
    background-image: url("../../../assets/images/img_equipment.webp");
  }
  .mark, .quantity, .date {
    color: #82868C;
  }
</style>
