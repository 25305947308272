<template>
  <div class="select-affect-equipment">
    <div class="begin relative">
      <div class="flex w-full relative" @click="openList">
        <div class="ipt relative justify-center items-center px-4 py-7 w-full cursor-pointer border rounded-lg">
          <div class="current-month-text mr-4">
            <div class="action flex items-center w-full">
              <div v-if="status.picture !== ''" class="picture rounded-lg">
                <img class="character-option-icon w-8 h-8" :src="status.picture">
              </div>
              <div class="name ml-4 text-black">{{ status.name }}</div>
            </div>
          </div>
          <div class="town-select pointer-events-none absolute inset-y-0 right-0 flex items-center px-4">
            <icon :data="icons.dropdown" original width="25" height="25" />
          </div>
        </div>
      </div>
      <div
        v-if="wantToSelect"
        class="absolute select-list bg-white border w-full overflow-y-scroll max-h-44"
      >
        <div
          v-for="(item, key) in statusList"
          :key="key"
          class="listes flex px-4 py-2 cursor-pointer w-full border-b"
          @click="itemIsSelected(item)"
        >
          <div class="action flex items-center w-full">
            <div class="picture rounded-lg">
              <img class="character-option-icon w-8 h-8" :src="item.picture">
            </div>
            <div class="name ml-4">
              <div class="title text-black">{{ item.name }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dropdown from '../../../assets/icons/ic_drop_down.svg'
export default {
  name: 'select-affect-equipment',
  props: {
    statusList: {
      type: Array,
      default: function () {
        return [
          {
            picture: 'label',
            name: 'value'
          }
        ]
      }
    }
  },
  data () {
    return {
      icons: { dropdown },
      status: {
        name: '',
        picture: ''
      },
      wantToSelect: false
    }
  },
  methods: {
    openList () {
      this.wantToSelect = !this.wantToSelect
    },
    itemIsSelected (item) {
      this.wantToSelect = !this.wantToSelect
      this.status = item
      this.$emit('selectedValue', item)
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "src/assets/styles/sass/variables";
  .ipt {
    background: $team_gray_popup;
  }
  .listes:hover {
    background: rgb(243 244 246);
  }
</style>
