const districts = [
  'FOUNOUGO',
  'GOMPAROU',
  'GOUMORI',
  'KOKEY',
  'KOKIBOROU',
  'OUNET',
  'SOMPEROUKOU',
  'SOROKO',
  'TOURA',
  'BANIKOARA',
  'BAGOU',
  'GOUNAROU',
  'SORI',
  'SOUGOU-KPAN-TROSSI',
  'WARA',
  'GOGOUNOU',
  'ANGARADEBOU',
  'BENSEKOU',
  'DONWARI',
  'KASSAKOU',
  'SAAH',
  'SAM',
  'SONSORO',
  'KANDI 1',
  'KANDI 2',
  'KANDI 3',
  'BIRNI LAFIA',
  'BOGO-BOGO',
  'KOMPA',
  'MONSEY',
  'KARIMAMA',
  'GAROU',
  'GUENE',
  'MADECALI',
  'TOUMBOUTOU',
  'MALANVILLE',
  'LIBANTE',
  'LIBOUSSOU',
  'LOUGOU',
  'SOKOTINDJI',
  'SEGBANA',
  'DIPOLI',
  'KORONTIERE',
  'KOUSSOUCOINGOU',
  'MANTA',
  'NATA',
  'TABOTA',
  'BOUKOUMBE',
  'DATORI',
  'KOUNTORI',
  'TAPOGA',
  'COBLY',
  'BRIGNAMARO',
  'FIROU',
  'KAOBAGOU',
  'KEROU',
  'BIRNI',
  'CHABI-COUMA',
  'FOO-TANCE',
  'GUILMARO',
  'OROUKAYO',
  'KOUANDE',
  'DASSARI',
  'GOUANDE',
  'NODI',
  'TANTEGA',
  'TCHANHOUNCOSSI',
  'MATERI',
  'KOTOPOUNGA',
  'KOUABA',
  'KOUANDATA',
  'PERMA',
  'TCHOUMI-TCHOUMI',
  'NATITINGOU I',
  'NATITINGOU II',
  'NATITINGOU III',
  'PEPORIYAKOU',
  'GNEMASSON',
  'TOBRE',
  'PEHUNCO',
  'COTIAKOU',
  "N'DAHONTA",
  'TAIACOU',
  'TANONGOU',
  'TANGUIETA',
  'KOUARFA',
  'TAMPEGRE',
  'TOUKOUNTOUNA',
  'AKASSATO',
  'GODOMEY',
  'GOLO-DJIGBE',
  'HEVIE',
  'KPANROUN',
  'OUEDO',
  'TOGBA',
  'ZINVIE',
  'ABOMEY-CALAVI',
  'AGBANOU',
  'AHOUANNONZOUN',
  'ATTOGON',
  'AVAKPA',
  'AYOU',
  'HINVI',
  'LISSEGAZOUN',
  'LON-AGONMEY',
  'SEKOU',
  'TOKPA',
  'ALLADA CENTRE',
  'TOGOUDO',
  'AGANMALOME',
  'AGBANTO',
  'AGONKANME',
  'DEDOME',
  'DEKANME',
  'SEGBEYA',
  'SEGBOHOUE',
  'TOKPA-DOME',
  'KPOMASSE CENTRE',
  'AVLEKETE',
  'DJEGBADJI',
  'GAKPE',
  'HOUAKPE-DAHO',
  'PAHOU',
  'SAVI',
  'OUIDAH I',
  'OUIDAH II',
  'OUIDAH III',
  'OUIDAH IV',
  'AHOMEY-LOKPO',
  'DEKANMEY',
  'GANVIE 1',
  'GANVIE 2',
  'HOUEDO-AGUEKON',
  'VEKKY',
  'SO-AVA',
  'AGUE',
  'COLLI',
  'COUSSI',
  'DAME',
  'DJANGLANME',
  'HOUEGBO',
  'KPOME',
  'SEHOUE',
  'SEY',
  'TOFFO',
  'AVAME',
  'AZOHOUE-ALIHO',
  'AZOHOUE-CADA',
  'TORI-CADA',
  'TORI-GARE',
  'TORI-BOSSITO',
  'ADJAN',
  'DAWE',
  'DJIGBE',
  'DODJI-BATA',
  'HEKANME',
  'KOUNDOKPOE',
  'SEDJE-DENOU',
  'SEDJE-HOUEGOUDO',
  'TANGBO',
  'YOKPO',
  'ZE',
  'BEROUBOUAY',
  'BOUANRI',
  'GAMIA',
  'INA',
  'BEMBEREKE',
  'BASSO',
  'BOUCA',
  'DERASSI',
  'DUNKASSA',
  'PEONGA',
  'KALALE',
  'BORI',
  'GBEGOUROU',
  'OUENOU',
  'SIRAROU',
  "N'DALI",
  'BIRO',
  'GNONKOUROKALI',
  'OUENOU',
  'SEREKALI',
  'SUYA',
  'TASSO',
  'NIKKI',
  '1ER ARRONDISSEMENT',
  '2EME ARRONDISSEMENT',
  '3EME ARRONDISSEMENT',
  'GNINSY',
  'GUINAGOUROU',
  'KPEBIE',
  'PANE',
  'SONTOU',
  'PERERE',
  'FO-BOURE',
  'SEKERE',
  'SIKKI',
  'SINENDE',
  'ALAFIAROU',
  'BETEROU',
  'GORO',
  'KIKA',
  'SANSON',
  'TCHATCHOU',
  'TCHAOUROU',
  'AGOUA',
  'AKPASSI',
  'ATOKOLIBE',
  'BOBE',
  'GOUKA',
  'KOKO',
  'LOUGBA',
  'PIRA',
  'BANTE',
  'AKOFFODJOULE',
  'GBAFFO',
  'KERE',
  'KPINGNI',
  'LEMA',
  'PAOUINGNAN',
  'SOCLOGBO',
  'TRE',
  'DASSA I',
  'DASSA II',
  'AKLAMPA',
  'ASSANTE',
  'GOME',
  'KPAKPAZA',
  'MAGOUMI',
  'OUEDEME',
  'SOKPONTA',
  'THIO',
  'ZAFFE',
  'GLAZOUE',
  'CHALLA-OGOI',
  'DJEGBE',
  'GBANLIN',
  'IKEMON',
  'KILIBO',
  'LAMINOU',
  'ODOUGBA',
  'TOUI',
  'OUESSE',
  'DJALLOUKOU',
  'DOUME',
  'GOBADA',
  'KPATABA',
  'LAHOTAN',
  'LEMA',
  'LOGOZOHE',
  'MONKPA',
  'OTTOLA',
  'OUESSE',
  'TCHETTI',
  'SAVALOU-AGA',
  'SAVALOU-AGBADO',
  'SAVALOU-ATTAKE',
  'BESSE',
  'KABOUA',
  'OFFE',
  'OKPARA',
  'SAKIN',
  'ADIDO',
  'BONI',
  'PLATEAU',
  'ATOMEY',
  'AZOVE',
  'DEKPO-CENTRE',
  'GODOHOU',
  'KISSAMEY',
  'LONKLY',
  'APLAHOUE',
  'ADJINTIMEY',
  'BETOUMEY',
  'GOHOMEY',
  'HOUEGAMEY',
  'KINKINHOUE',
  'KOKOHOUE',
  'KPOBA',
  'SOKOUHOUE',
  'DJAKOTOMEY I',
  'DJAKOTOMEY II',
  'AYOMI',
  'DEVE',
  'HONTON',
  'LOKOGOHOUE',
  'MADJRE',
  'TOTCHANGNI CENTRE',
  'TOTA',
  'ADJAHONME',
  'AHOGBEYA',
  'AYAHOHOUE',
  'DJOTTO',
  'HONDJIN',
  'LANTA',
  'TCHIKPE',
  'KLOUEKANME',
  'ADOUKANDJI',
  'AHODJINNAKO',
  'AHOMADEGBE',
  'BANIGBE',
  'GNIZOUNME',
  'HLASSAME',
  'LOKOGBA',
  'TCHITO',
  'TOHOU',
  'ZALLI',
  'LALO',
  'ADJIDO',
  'AVEDJIN',
  'DOKO',
  'HOUEDOGLI',
  'MISSINKO',
  'TANNOU-GOLA',
  'TOVIKLIN',
  'ALEDJO',
  'MANIGRI',
  'PENESSOULOU',
  'BASSILA',
  'ANANDANA',
  'PABEGOU',
  'SINGRE',
  'COPARGO',
  'BAREI',
  'BARIENOU',
  'BELLEFOUNGOU',
  'BOUGOU',
  'KOLOCONDE',
  'ONKLOU',
  'PARTAGO',
  'PELEBINA',
  'SEROU',
  'DJOUGOU I',
  'DJOUGOU II',
  'DJOUGOU III',
  'BADJOUDE',
  'KOMDE',
  'SEMERE 1',
  'SEMERE 2',
  'TCHALINGA',
  'OUAKE',
  '1ER ARRONDISSEMENT',
  '2EME ARRONDISSEMENT',
  '3EME ARRONDISSEMENT',
  '4EME ARRONDISSEMENT',
  '5EME ARRONDISSEMENT',
  '6EME ARRONDISSEMENT',
  '7EME ARRONDISSEMENT',
  '8EME ARRONDISSEMENT',
  '9EME ARRONDISSEMENT',
  '10EME ARRONDISSEMENT',
  '11EME ARRONDISSEMENT',
  '12EME ARRONDISSEMENT',
  '13EME ARRONDISSEMENT',
  'ADOHOUN',
  'ATCHANNOU',
  'DEDEKPOE',
  'KPINNOU',
  'ATHIEME',
  'AGBODJI',
  'BADAZOUIN',
  'GBAKPODJI',
  'LOBOGO',
  'POSSOTOME',
  'YEGODOE',
  'BOPA',
  'AGATOGBO',
  'AKODEHA',
  'OUEDEME-PEDAH',
  'OUMAKO',
  'COME',
  'ADJAHA',
  'AGOUE',
  'AVLO',
  'DJANGLANMEY',
  'GBEHOUE',
  'SAZUE',
  'GRAND-POPO',
  'DAHE',
  'DOUTOU',
  'HONHOUE',
  'ZOUNGBONOU',
  'HOUEYOGBE',
  'SE',
  'AGAME',
  'HOUIN',
  'KOUDO',
  'OUEDEME-ADJA',
  'LOKOSSA',
  'AGLOGBE',
  'HONVIE',
  'MALANHOUI',
  'MEDEDJONOU',
  'ADJARRA 1',
  'ADJARRA 2',
  'AKPADANOU',
  'AWONOU',
  'AZOWLISSE',
  'DEME',
  'GANGBAN',
  'KODE',
  'TOGBOTA',
  'ADJOHOUN',
  'AVAGBODJI',
  'HOUEDOME',
  'ZOUNGAME',
  'GOME-SOTA',
  'KATAGON',
  'VAKON',
  'ZOUNGBOME',
  'AKPRO-MISSERETE',
  'ATCHOUKPA',
  'DJOMON',
  'GBOZOUME',
  'KOUTI',
  'OUANHO',
  'SADO',
  'AVRANKOU',
  'AFFAME',
  'ATCHONSA',
  'DAME-WOGON',
  'HOUNVIGUE',
  'BONOU',
  'DEKIN',
  'GBEKO',
  'HOUEDOMEY',
  'HOZIN',
  'KESSOUNOU',
  'ZOUNGUE',
  'DANGBO',
  '1ER ARRONDISSEMENT',
  '2EME ARRONDISSEMENT',
  '3EME ARRONDISSEMENT',
  '4EME ARRONDISSEMENT',
  '5EME ARRONDISSEMENT',
  'AGBLANGANDAN',
  'AHOLOUYEME',
  'DJEREGBE',
  'EKPE',
  'TOHOUE',
  'SEME-PODJI',
  'IKPINLE',
  'KPOULOU',
  'MASSE',
  'OKO-AKARE',
  'TATONNONKON',
  'ADJA-OUERE',
  'BANIGBE',
  'DAAGBE',
  'KO-KOUMOLOU',
  'LAGBE',
  'TCHAADA',
  'IFANGNI',
  'ADAKPLAME',
  'IDIGNY',
  'KPANKOU',
  'ODOMETA',
  'OKPOMETA',
  'KETOU',
  'AHOYEYE',
  'IGANA',
  'ISSABA',
  'TOWE',
  'POBE',
  'AGUIDI',
  'ITA-DJEBOU',
  'TAKON',
  'YOKO',
  'SAKETE 1',
  'SAKETE 2',
  'AGBOKPA',
  'DETOHOU',
  'SEHOUN',
  'ZOUNZONME',
  'DJEGBE',
  'HOUNLI',
  'VIDOLE',
  'ADANHONDJIGO',
  'ADINGNIGON',
  'KINTA',
  'KPOTA',
  'LISSAZOUNME',
  'SAHE',
  'SINWE',
  'TANVE',
  'ZOUNGOUNDO',
  'AGBANGNIZOUN',
  'AGONGOINTO',
  'AVOGBANNA',
  'GNIDJAZOUN',
  'LISSEZOUN',
  'OUASSAHO',
  'PASSAGON',
  'SACLO',
  'SODOHOME',
  'BOHICON I',
  'BOHICON II',
  'HOUEKO',
  'ADOGBE',
  'GOUNLI',
  'HOUIN-HOUNSO',
  'LAINTA-COGBE',
  'NAOGON',
  'SOLI',
  'ZOGBA',
  'AGONDJI',
  'AGOUNA',
  'DAN',
  'DOHOUIME',
  'GOBAIX',
  'HOUTO',
  'MONSOUROU',
  'MOUGNON',
  'OUMBEGAME',
  'SETTO',
  'ZOUNKON',
  'DJIDJA CENTRE',
  'DASSO',
  'SAGON',
  'TOHOUES',
  'OUINHI CENTRE',
  'AGONLIN-HOUEGBO',
  'BANAME',
  'DON-TAN',
  'DOVI',
  'KPEDEKPO',
  'ZAGNANADO CENTRE',
  'ALLAHE',
  'ASSANLIN',
  'HOUNGOME',
  'KPAKPAME',
  'KPOZOUN',
  'ZA-TANTA',
  'ZEKO',
  'ZA-KPOTA',
  'AKIZA',
  'AVLAME',
  'CANA I',
  'CANA II',
  'DOME',
  'KOUSSOUKPA',
  'KPOKISSA',
  'MASSI',
  'TANWE-HESSOU',
  'ZOUKOU',
  'ZOGBODOMEY CENTRE'
]
export default districts
