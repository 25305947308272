<template>
  <div class="contractual-infos">
    <div class="begin">
      <div class="header flex items-center justify-between cursor-pointer" @click="showContent">
        <div class="title font-medium uppercase">{{ $t('contractualInformation') }}</div>
        <div class="icons">
          <icon v-if="isContent" :data="icons.open" original width="15" height="15" />
          <icon v-if="!isContent" :data="icons.close" original width="15" height="15" />
        </div>
      </div><br>
      <div v-if="isContent" class="body">
        <div class="infos">
          <div class="question">{{ $t('hiringDate') }}</div>
          <div class="answer text-lg capitalize">{{ new Date(isContractual.hiringDate).toLocaleDateString('FR', options) }}</div>
        </div><br>
        <div class="infos">
          <div class="question">{{ $t('function') }}</div>
          <div class="answer text-lg">
            <div v-if="isContractual.agentFunction === 'administrative'">{{ 'Administration centrale' }}</div>
            <div v-if="isContractual.agentFunction === 'supervisor'">{{ 'Superviseur' }}</div>
            <div v-if="isContractual.agentFunction === 'agent'">{{ 'Agent' }}</div>
            <div v-if="isContractual.agentFunction === 'manager'">{{ 'Chef site' }}</div>
          </div>
        </div><br>
        <div class="infos">
          <div class="question">{{ $t('contractType') }}</div>
          <div class="answer text-lg">{{ isContractual.contractType }}</div>
        </div><br>
        <div class="infos">
          <div class="question">{{ $t('contractTerm') }}</div>
          <div class="answer text-lg capitalize">
            <div v-if="isNaN(isContractual.contractTerm)">-</div>
            <div v-else>{{ new Date(isContractual.contractTerm).toLocaleDateString('FR', options) }}</div>
          </div>
        </div><br>
        <div class="infos">
          <div class="question">{{ $t('salary') }}</div>
          <div class="answer text-lg">{{ new Intl.NumberFormat('FR', { style: 'currency', currency: 'XOF' }).format(isContractual.salary) }}</div>
        </div><br>
      </div>
    </div>
  </div>
</template>

<script>
import open from '../../../assets/icons/ic_open_up.svg'
import close from '../../../assets/icons/ic_open_down.svg'
export default {
  name: 'contractual-infos',
  props: {
    isContractual: Object
  },
  data () {
    return {
      icons: { open, close },
      isContent: false,
      options: { year: 'numeric', month: 'long', day: '2-digit' }
    }
  },
  methods: {
    showContent () {
      this.isContent = !this.isContent
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "src/assets/styles/sass/variables";
  .svg-fill {
    fill: transparent;
  }
  .title {
    color: $team_blue;
  }
  .question {
    color: #909090;
  }
</style>
