<template>
  <div class="change-designated-site">
    <popup-base>
      <div class="begin bg-white m-auto w-3/5 border">
        <div class="header p-8 flex justify-between">
          <div class="title-bloc">
            <div class="title text-2xl font-medium">{{ $t('changeAffectation') }}</div>
          </div>
          <div class="close cursor-pointer" @click="closeAdd">
            <icon :data="icons.close" original width="25" height="25"/>
          </div>
        </div>
        <div class="body p-8">
          <div class="form">
            <div class="login-form">
              <div class="label mb-2">{{ $t('siteName') }}</div>
              <div class="input-bloc">
                <designated-site-agent
                  :actual-site="actualAgentSite"
                  @updateAgent="updateAgent"
                />
              </div>
            </div><br>
            <div class="login-form mb-4">
              <div class="label mb-2">{{ $t('custodyPeriod') }}</div>
              <div class="input-bloc">
                <Multiselect
                  v-model="duration"
                  :options="optionsDuration"
                  placeholder="8H"
                  :classes="{
                    container: 'ipt relative mx-auto w-full flex items-center justify-end box-border cursor-pointer border rounded-lg p-5 leading-snug outline-none',
                    clear: 'hidden',
                    optionSelected: 'text-black bg-none',
                    optionSelectedPointed: 'text-gray-800 bg-gray-100'
                  }"
                  @select="selectDuration"
                />
              </div>
            </div>
            <div v-if="isPeriodicity" class="login-form mb-4">
              <div class="label mb-2">{{ $t('periodicity') }}</div>
              <div class="input">
                <Multiselect
                  v-model="periodicity"
                  :options="options"
                  placeholder="Par jour"
                  :classes="{
                    container: 'ipt relative mx-auto w-full flex items-center justify-end box-border cursor-pointer border rounded-lg p-5 leading-snug outline-none',
                    clear: 'hidden',
                    optionSelected: 'text-black bg-none',
                    optionSelectedPointed: 'text-gray-800 bg-gray-100'
                  }"
                />
              </div>
            </div>
            <div class="login-form mb-4">
              <div class="label mb-2">{{ $t('uphillTime') }}</div>
              <div class="input">
                <v-date-picker class="inline-block birth h-full w-full z-50" v-model="hour" mode="time" is24hr>
                  <template v-slot="{ inputValue, togglePopover }">
                    <div class="flex items-center">
                      <input
                        :value="inputValue"
                        placeholder="07:00"
                        readonly
                        class="ipt w-full p-4 appearance-none border border-r-0 rounded-l-lg focus:outline-none"
                        @click="togglePopover()"
                      />
                      <button
                        class="ipt p-4 border border-l-0 rounded-r-lg focus:outline-none"
                        @click="togglePopover()"
                      >
                        <icon :data="icons.clock" original width="15" height="15"/>
                      </button>
                    </div>
                  </template>
                </v-date-picker>
              </div>
            </div>
            <div class="login-form mb-4">
              <div class="label mb-2">{{ $t('beginningDate') }}</div>
              <div class="input">
                <v-date-picker class="inline-block birth h-full w-full z-50" :min-date="new Date()" v-model="date">
                  <template v-slot="{ inputValue, inputEvents, togglePopover }">
                    <div class="flex items-center">
                      <input
                        :value="inputValue"
                        placeholder="12/08/1993"
                        class="ipt w-full p-4 appearance-none border border-r-0 rounded-l-lg focus:outline-none"
                        v-on="inputEvents"
                      />
                      <button
                        class="ipt p-4 border border-l-0 rounded-r-lg focus:outline-none"
                        @click="togglePopover()"
                      >
                        <icon :data="icons.calendar" original width="15" height="15"/>
                      </button>
                    </div>
                  </template>
                </v-date-picker>
              </div>
            </div>
            <div class="login-form mb-4">
              <div class="label mb-2">{{ $t('role') }}</div>
              <div class="input">
                <Multiselect
                  v-model="role"
                  :options="optionsRole"
                  :classes="{
                    container: 'ipt relative mx-auto w-full flex items-center justify-end box-border cursor-pointer border rounded-lg p-5 leading-snug outline-none',
                    clear: 'hidden',
                    optionSelected: 'text-black bg-none',
                    optionSelectedPointed: 'text-gray-800 bg-gray-100'
                  }"
                />
              </div>
            </div>
            <div v-if="error" class="error text-red-500 text-center mb-4">
              {{ $t('errorAddAdmin') }}
            </div>
            <div class="login-button">
              <button-base :label="$t('saveButton')" :is-loading="loading" @click="changeAffectation" />
            </div>
          </div>
        </div>
      </div>
    </popup-base>
  </div>
</template>

<script>
import PopupBase from '@/components/helper/add/popup-base'
import close from '@icon/ic_close.svg'
import DesignatedSiteAgent from '@/components/helper/agents/designated-site-agent'
import Multiselect from '@vueform/multiselect'
import calendar from '@icon/ic_calendar.svg'
import clock from '@icon/ic_clock_time.svg'
import ButtonBase from '@/components/helper/add/button-base'
import { arrayRemove, doc, getDoc, updateDoc, setDoc, arrayUnion } from 'firebase/firestore'
import { firestore } from '@/main'
export default {
  name: 'change-designated-site',
  components: { ButtonBase, DesignatedSiteAgent, PopupBase, Multiselect },
  props: {
    actualAgentSite: Object,
    actualAgent: Object
  },
  data () {
    return {
      icons: { close, calendar, clock },
      loading: false,
      error: false,
      selectedSite: '',
      duration: '',
      agent: '',
      periodicity: '',
      role: '',
      hour: new Date().getTime(),
      date: new Date().getTime(),
      isPeriodicity: false,
      selectedAgent: '',
      optionsDuration: ['8H', '12H', '24H'],
      options: ['Par jour', 'Par nuit'],
      optionsRole: ['Chef site', 'Agent simple', 'Administration Centrale']
    }
  },
  created () {
    // console.log(this.equipmentsDetails)
  },
  methods: {
    async changeAffectation () { // CHANGE AGENT AFFECTATION
      if (this.hour === '' || this.hour === '' || this.duration === '' || this.role === '') {
        this.error = true
      } else {
        this.error = false
        this.loading = true
        if (this.selectedSite === '') {
          this.selectedSite = this.actualAgentSite.uid
          const docRef = doc(firestore, 'designation', this.actualAgentSite.uid)
          const docSnap = await getDoc(docRef)

          if (docSnap.exists()) {
            console.log('Document data:', docSnap.data())
            for (const i in docSnap.data().designatedAgents) {
              if (this.actualAgent.uid === docSnap.data().designatedAgents[i].agent) {
                console.log(docSnap.data().designatedAgents[i])
                const designationRef = doc(firestore, 'designation', this.actualAgentSite.uid)
                await updateDoc(designationRef, {
                  designatedAgents: arrayRemove(docSnap.data().designatedAgents[i])
                })
                console.log('Agent delete successful from this site')
                // ADD NEW AFFECTATION TO AGENT
                await this.addNewAffectation()
                this.$emit('openSuccess', true)
                this.closeAdd()
              }
            }
          } else {
            console.log('No such document!')
          }
        } else {
          const docRef = doc(firestore, 'designation', this.actualAgentSite.uid)
          const docSnap = await getDoc(docRef)

          if (docSnap.exists()) {
            console.log('Document data:', docSnap.data())
            for (const i in docSnap.data().designatedAgents) {
              if (this.actualAgent.uid === docSnap.data().designatedAgents[i].agent) {
                console.log(docSnap.data().designatedAgents[i])
                const designationRef = doc(firestore, 'designation', this.actualAgentSite.uid)
                await updateDoc(designationRef, {
                  designatedAgents: arrayRemove(docSnap.data().designatedAgents[i])
                })
                console.log('Agent delete successful from this site')
                // ADD NEW AFFECTATION TO AGENT
                await this.addNewAffectation()
                this.$emit('openSuccess', true)
                this.closeAdd()
              }
            }
          } else {
            console.log('No such document!')
          }
        }
      }
    },
    async addNewAffectation () {
      const change = {}
      change.agent = this.actualAgent.uid
      change.date = new Date(this.date).getTime()
      change.hour = new Date(this.hour).getTime()
      change.duration = this.duration
      change.periodicity = this.periodicity
      change.role = this.role
      const inputs = []
      inputs.push(change)
      // console.log(inputs)
      const data = {
        uid: this.selectedSite,
        addedBy: this.$store.getters.userId,
        createdAt: new Date().getTime(),
        siteId: this.selectedSite,
        designatedAgents: inputs
      }
      const docRef = doc(firestore, 'designation', this.selectedSite)
      const docSnap = await getDoc(docRef)

      if (docSnap.exists()) {
        // console.log('Document data:', docSnap.data())
        const designationRef = doc(firestore, 'designation', this.selectedSite)
        await updateDoc(designationRef, {
          designatedAgents: arrayUnion(...inputs)
        })
      } else {
        console.log('No such document!')
        await setDoc(doc(firestore, 'designation', this.selectedSite), data)
      }

      // UPDATE AGENTS INFORMATION
      const siteId = []
      siteId.push(this.selectedSite)
      const agentRef = doc(firestore, 'agents', this.actualAgent.uid)
      await updateDoc(agentRef, {
        isDesignated: true,
        status: 'active',
        serviceHour: new Date(this.hour).getTime(),
        serviceDate: new Date(this.date).getTime(),
        serviceSite: this.selectedSite
      })
    },
    selectDuration () { // SHOW PERIODICITY IF DURATION IS SELECTED
      if (this.duration === '24H') {
        this.isPeriodicity = false
      } else {
        this.isPeriodicity = true
      }
    },
    updateAgent (val) {
      this.selectedSite = val
      console.log(val)
    },
    closeAdd () {
      this.$emit('closeChangeAffectation', false)
    }
  }
}
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
<style lang="scss" scoped>
  @import "src/assets/styles/sass/variables";
  .begin {
    border-top: 4px solid $team_blue;
  }
  .header {
    background: $team_gray_popup;
  }
  .label {
    color: $team_label;
  }
  .input-bloc::v-deep {
    .vc-container {
      font-family: $font-default !important;
      .vc-time-date[data-v-63f66eaa] {
        display: none !important;
      }
      .vc-day-content {
        font-weight: 400 !important;
      }
    }
  }
  .login-button::v-deep {
    .button-base {
      text-align: right;
      text-align: -moz-right;
      text-align: -webkit-right;
      .page-button-real {
        border-radius: .25rem;
        width: 50%;
        h4 {
          font-size: 1.25rem;
        }
      }
    }
  }
</style>
