<template>
  <div class="import-list">
    <popup-base>
      <div class="begin bg-white w-3/4 m-auto border">
        <div class="header p-8 text-2xl font-medium flex items-center justify-between">
          <div class="title">{{ $t('importAgentFile') }}</div>
          <div class="close cursor-pointer" @click="closeImport">
            <icon :data="icons.close" original width="25" height="25"/>
          </div>
        </div>
        <div class="body p-8">
          <div class="form py-6">
            <div class="form-input w-full p-24 border border-dashed rounded-2xl text-center cursor-pointer" @click="previewFiles">
              <div class="icon-download mb-8"><icon :data="icons.download" original width="50" height="50"/></div>
              <div class="name">{{ $t('clickToImport') }}</div>
            </div>
            <div v-if="fileName !== ''" class="my-4 border rounded-lg p-4 w-full">{{ fileName }}</div>
          </div>
          <div v-if="error" class="error text-red-500 text-center">
            {{ $t('siteAddError') }}
          </div><br>
          <div class="buttons flex items-center justify-end">
            <div class="cancel-button mr-3">
              <button-base
                :label="$t('cancelButton')"
                fontcolor="#909090"
                background="#FFFFFF"
                @click="closeImport"
              />
            </div>
            <div class="valid-button ml-3">
              <button-base
                :label="$t('continuousButton')"
                :is-loading="loader"
                @click="saveFile"
              />
            </div>
          </div>
        </div>
      </div>
    </popup-base>
  </div>
</template>

<script>
import PopupBase from '../add/popup-base'
import close from '../../../assets/icons/ic_close.svg'
import download from '../../../assets/icons/ic_download.svg'
import ButtonBase from '../add/button-base'
import XLSX from 'xlsx/dist/xlsx.full.min.js'
import http from '../../../plugins/https'
import { doc, setDoc } from 'firebase/firestore'
import { firestore } from '../../../main'
import towns from '@/components/helper/add/town-base'
import districts from '@/components/helper/add/district-base'
export default {
  name: 'import-list',
  components: { ButtonBase, PopupBase },
  data () {
    return {
      icons: { close, download },
      fileName: '',
      file: [],
      loader: false,
      error: false,
      allTown: towns,
      allDistrict: districts
    }
  },
  methods: {
    saveFile () {
      if (this.file.length === 0) {
        this.error = true
      } else {
        this.error = false
        this.loader = true
        for (let i = 0; i < this.file.length; i++) {
          console.log(this.file[i])

          let village = ''
          if (this.allTown.includes(this.file[i].city.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toUpperCase())) {
            village = this.file[i].city.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toUpperCase()
          } else if (this.allDistrict.includes(this.file[i].city.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toUpperCase())) {
            village = this.file[i].city.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toUpperCase()
          } else if (this.file[i].city.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase() === 'seme-kpodji') {
            village = 'SEME-PODJI'
          }
          console.log(village)

          /* for (const j in this.allTown) {
            if (this.file[i].city.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase() === this.allTown[j].toLowerCase()) {
              village = this.allTown[j]
            }
          }

          for (const k in this.allDistrict) {
            if (this.file[i].city.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase() === this.allDistrict[k].toLowerCase()) {
              village = this.allDistrict[k]
            }
          } */

          const date = this.excelDateToJSDate(this.file[i].dateAnniversaire).toLocaleDateString('FR')
          console.log(date.substring(0, 2))
          let matricule = ''
          matricule = 'AG' + '-' + Math.random().toString(8).substr(2, 7) + this.file[i].name.charAt(0).toUpperCase()
          // matricule = this.file[i].poste.substring(0, 2).toUpperCase() + '-' + Math.random().toString(8).substr(2, 7) + this.file[i].name.charAt(0).toUpperCase()
          console.log(matricule)
          const params = new URLSearchParams()
          const agentEmail = matricule + '@teamsecu.com'
          params.append('email', agentEmail)
          http.post('https://us-central1-teamsecu-f002b.cloudfunctions.net/exampleCreate', params)
            .then(async response => {
              console.log(response)
              console.log(response.uid)

              // CREATE AGENT INFORMATION
              const updateAgent = doc(firestore, 'agents', response.uid)
              await setDoc(updateAgent, {
                uid: response.uid,
                createdAt: new Date().getTime(),
                name: this.file[i].name,
                matricule: matricule,
                profilePicture: '',
                email: this.file[i].email || '',
                birthday: this.excelDateToJSDate(this.file[i].dateAnniversaire).getTime() || null,
                phone: this.file[i].phone || null,
                city: village || '',
                address: this.file[i].address || '',
                agentFunction: this.file[i].poste || '',
                hiringDate: this.excelDateToJSDate(this.file[i].embaucheDate).getTime() || null,
                contractType: this.file[i].typeContrat || '',
                contractTerm: this.excelDateToJSDate(this.file[i].termeContrat).getTime() || null,
                salary: this.file[i].salaire || null,
                addedBy: this.$store.getters.userId,
                birthdayAct: '',
                identityCard: '',
                identityPicture: '',
                residenceAttestation: '',
                childrenBirthdayAct: '',
                wifeBirthdayAct: '',
                weddingAct: '',
                recommendationLetter: '',
                criminalRecord: '',
                servicePlug: '',
                status: 'active',
                isDesignated: false,
                haveEquipment: false,
                numberAffected: 0
              }).then(async res => {
                console.log('Created successful:' + res)
                // window.location.reload()
              }).catch(err => {
                console.log(err)
              })
            }).catch(error => {
              console.log(error)
            })

          setTimeout(() => {
            this.$emit('openSuccessImport', true)
          }, 60000)
        }
      }
    },
    previewFiles () { // TAKE EXCEL FILE AND CONVERT IT TO JSON FILE
      const vm = this
      const input = document.createElement('input')
      input.type = 'file'
      input.setAttribute('accept', '.xls, .xlsx')
      input.onchange = e => {
        const files = e.target.files
        const f = files[0]
        vm.fileName = f.name
        console.log(f)
        const reader = new FileReader()
        reader.onload = function (e) {
          const data = e.target.result
          const workbook = XLSX.read(data, { type: 'binary' })
          const sheetName = workbook.SheetNames[0]
          // console.log(workbook)
          const worksheet = workbook.Sheets[sheetName]
          // console.log(XLSX.utils.sheet_to_json(worksheet))
          vm.file = XLSX.utils.sheet_to_json(worksheet)
          // console.log(JSON.stringify(XLSX.utils.sheet_to_json(worksheet), undefined, 4))
        }
        reader.readAsArrayBuffer(f)
      }
      input.click()
    },
    excelDateToJSDate (date) {
      return new Date(Math.round((date - 25569) * 86400 * 1000))
    },
    closeImport () {
      this.$emit('closeImport', false)
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "src/assets/styles/sass/variables";
  .begin {
    border-top: 4px solid $team_blue;
  }
  .header, .form-input {
    background: $team_gray_popup;
  }
  .form-input {
    border-color: #8E8EA9;
  }
  .name {
    color: #909090;
  }
  .icon-download {
    text-align: -webkit-center;
    text-align: -moz-center;
    text-align: center;
  }
  .cancel-button::v-deep {
    .button-base {
      .page-button-real {
        border: none;
        h4 {
          font-size: 1.25rem;
        }
      }
    }
  }
  .valid-button::v-deep {
    .button-base {
      .page-button-real {
        border-radius: .25rem;
        // width: 18rem;
        h4 {
          font-size: 1.25rem;
        }
      }
    }
  }
</style>
