const towns = [
  'BANIKOARA',
  'GOGOUNOU',
  'KANDI',
  'KARIMAMA',
  'MALANVILLE',
  'SEGBANA',
  'BOUKOUMBE',
  'COBLY',
  'KEROU',
  'KOUANDE',
  'MATERI',
  'NATITINGOU',
  'OUASSA-PEHUNCO',
  'TANGUIETA',
  'TOUKOUNTOUNA',
  'ABOMEY-CALAVI',
  'ALLADA',
  'KPOMASSE',
  'OUIDAH',
  'SO-AVA',
  'TOFFO',
  'TORI-BOSSITO',
  'ZE',
  'BEMBEREKE',
  'KALALE',
  "N'DALI",
  'NIKKI',
  'PARAKOU',
  'PERERE',
  'SINENDE',
  'TCHAOUROU',
  'BANTE',
  'DASSA-ZOUME',
  'GLAZOUE',
  'OUESSE',
  'SAVALOU',
  'SAVE',
  'APLAHOUE',
  'DJAKOTOMEY',
  'DOGBO',
  'KLOUEKANMEY',
  'LALO',
  'TOVIKLIN',
  'BASSILA',
  'COPARGO',
  'DJOUGOU',
  'OUAKE',
  'COTONOU',
  'ATHIEME',
  'BOPA',
  'COME',
  'GRAND-POPO',
  'HOUEYOGBE',
  'LOKOSSA',
  'ADJARRA',
  'ADJOHOUN',
  'AGUEGUES',
  'AKPRO-MISSERETE',
  'AVRANKOU',
  'BONOU',
  'DANGBO',
  'PORTO-NOVO',
  'SEME-PODJI',
  'ADJA-OUERE',
  'IFANGNI',
  'KETOU',
  'POBE',
  'SAKETE',
  'ABOMEY',
  'AGBANGNIZOUN',
  'BOHICON',
  'COVE',
  'DJIDJA',
  'OUINHI',
  'ZAGNANADO',
  'ZA-KPOTA',
  'ZOGBODOMEY'
]
export default towns
