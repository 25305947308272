<template>
  <div class="agent-details">
    <div class="begin">
      <div class="header flex items-center justify-between mb-12">
        <div class="title-bloc flex items-center">
          <div class="icons mr-4 cursor-pointer" @click="goBack"><icon :data="icons.back" original width="40" height="40"/></div>
          <div class="title font-semibold text-2xl">{{ $t('agentDetails') }}</div>
        </div>
      </div>
      <div class="body">
        <div class="body-content bg-white p-8 rounded-xl border flex">
          <div class="part-1 w-1/3 mr-3">
            <div class="edition p-6 w-full flex items-center">
              <div
                v-if="agentsDetails.profilePicture !== ''"
                class="photo w-16 h-16 rounded-full bg-cover bg-no-repeat"
                v-bind:style="{ backgroundImage: 'url(' + agentsDetails.profilePicture + ')' }"
              ></div>
              <div
                v-else
                :style="backgroundDefine"
                class="pictures w-16 h-16 text-center bg-gray-500 pt-5 mr-2 rounded-full font-bold text-white"
              >
                {{ getFirstLetter(agentsDetails.name) }}
              </div>
              <div class="name-bloc ml-4">
                <div class="name font-medium text-xl">{{ agentsDetails.name }}</div>
                <div class="edit flex items-center cursor-pointer" @click="editAgentProfile">
                  <div class="edit-title mr-4">{{ $t('editProfile') }}</div>
                  <div class="icons"><icon :data="icons.edition" original width="15" height="15"/></div>
                </div>
              </div>
            </div>
            <div class="identification p-6 w-full mt-4">
              <identification-infos :is-identification="agentsDetails" />
            </div>
            <div class="identification p-6 w-full mt-4">
              <contractual-infos :is-contractual="agentsDetails" />
            </div>
            <div class="identification p-6 w-full mt-4">
              <join-pieces :is-join-pieces="agentsDetails" />
            </div>
          </div>
          <div class="part-2 w-2/3 ml-3">
            <div v-if="!isAffectedEquipment && !isRequest" class="principal">
              <div class="stats w-full mb-4">
                <agent-stats
                  :agents-stats="agentsDetails"
                  :request-number="allRequests.length"
                  @showRequests="showRequests"
                  @showAffectedEquipment="showAffectedEquipment"
                />
              </div>
              <div class="stats w-full mb-4">
                <assigned-site
                  :agents-details="agentsDetails"
                />
              </div>
              <div class="stats w-full mb-4 mt-12">
                <current-hands
                  :agents-details="agentsDetails"
                />
              </div>
            </div>
            <div class="second">
              <affected-equipment
                v-if="isAffectedEquipment"
                :details-agents="agentsDetails"
              />
              <requests
                v-if="isRequest"
                :agent-requests="allRequests"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import back from '../../../assets/icons/ic_go_back.svg'
import picture from '../../../assets/icons/ic_add_picture.svg'
import edition from '../../../assets/icons/ic_pencil.svg'
import IdentificationInfos from './identification-infos'
import ContractualInfos from './contractual-infos'
import JoinPieces from './join-pieces'
import AgentStats from './agent-stats'
import AssignedSite from './assigned-site'
import CurrentHands from './current-hands'
import AffectedEquipment from './affected-equipment'
import { collection, getDocs, orderBy, query, where } from 'firebase/firestore'
import { firestore } from '../../../main'
import Requests from './requests'
export default {
  name: 'agent-details',
  components: { Requests, AffectedEquipment, CurrentHands, AssignedSite, AgentStats, JoinPieces, ContractualInfos, IdentificationInfos },
  props: {
    agentsDetails: {
      type: Object
    }
  },
  data () {
    return {
      icons: { back, picture, edition },
      isAffectedEquipment: false,
      allRequests: [],
      isRequest: false,
      backgroundDefine: {
        backgroundColor: '#000000'
      }
    }
  },
  created () {
    // console.log(this.agentsDetails)
    this.getAllAgentsRequests()
    const colors = ['#01C8C8', '#FFA83D', '#6D1FEB', '#2F80ED', '#0DCE89', '#7D47B3', '#1ECC78', '#C1CD18', '#581845']
    const value = Math.floor(Math.random() * (colors.length + 1))
    if (value < colors.length) {
      this.backgroundDefine.backgroundColor = colors[value]
    } else {
      this.backgroundDefine.backgroundColor = colors[0]
    }
  },
  methods: {
    editAgentProfile () { // CLICK TO OPEN EDIT AGENT PROFILE
      this.$emit('editAgentProfile', this.agentsDetails)
      this.$emit('goBackToIndex', false)
    },
    async getAllAgentsRequests () { // GET ALL AGENTS REQUESTS LISTS
      const querySnapshot = await getDocs(query(collection(firestore, 'requests'),
        where('addedBy', '==', this.agentsDetails.uid),
        orderBy('createdAt', 'desc')
      ))
      querySnapshot.forEach((doc) => {
        // console.log(doc.id, ' => ', doc.data())
        this.allRequests.push(doc.data())
      })
    },
    showRequests (val) {
      this.isRequest = val
    },
    showAffectedEquipment (val) {
      this.isAffectedEquipment = val
    },
    goBack () { // GO BACK TO INDEX
      if (this.isAffectedEquipment === false && this.isRequest === false) {
        this.$emit('goBackToIndex', false)
      } else if (this.isAffectedEquipment === true) {
        this.showAffectedEquipment(false)
      } else if (this.isRequest === true) {
        this.showRequests(false)
      }
    },
    getFirstLetter (fullname) {
      const nameTable = fullname.split(' ')
      if (nameTable.length >= 2) {
        return nameTable[0].charAt(0).toUpperCase() + nameTable[1].charAt(0).toUpperCase()
      } else {
        return nameTable[0].charAt(0).toUpperCase() + nameTable[0].charAt(1).toUpperCase()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "src/assets/styles/sass/variables";
  .svg-fill {
    fill: transparent;
  }
  .edition, .identification {
    background: $team_gray_popup;
  }
  .photo {
    background-image: url("../../../assets/images/img_user.webp");
  }
  .edit-title {
    color: #017D01;
  }
</style>
