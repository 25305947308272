<template>
  <div class="join-pieces">
    <div class="begin">
      <div class="header flex items-center justify-between cursor-pointer" @click="showContent">
        <div class="title font-medium uppercase">{{ $t('joinPieces') }}</div>
        <div class="icons">
          <icon v-if="isContent" :data="icons.open" original width="15" height="15" />
          <icon v-if="!isContent" :data="icons.close" original width="15" height="15" />
        </div>
      </div><br>
      <div v-if="isContent" class="body">
        <div class="infos flex items-center">
          <div class="question text-lg mr-3">{{ $t('birthdayAct') }}</div>
          <div v-if="isJoinPieces.birthdayAct !== ''" class="answer cursor-pointer ml-3" @click="openBirthdayAct">
            <icon :data="icons.link" original width="20" height="20" />
          </div>
        </div><br>
        <div class="infos flex items-center">
          <div class="question text-lg mr-3">{{ $t('identityCard') }}</div>
          <div v-if="isJoinPieces.identityCard !== ''" class="answer cursor-pointer ml-3" @click="openIdentityCard">
            <icon :data="icons.link" original width="20" height="20" />
          </div>
        </div><br>
        <div class="infos flex items-center">
          <div class="question text-lg mr-3">{{ $t('identityPicture') }}</div>
          <div v-if="isJoinPieces.identityPicture !== ''" class="answer cursor-pointer ml-3" @click="openIdentityPicture">
            <icon :data="icons.link" original width="20" height="20" />
          </div>
        </div><br>
        <div class="infos flex items-center">
          <div class="question text-lg mr-3">{{ $t('residenceAttestation') }}</div>
          <div v-if="isJoinPieces.residenceAttestation !== ''" class="answer cursor-pointer ml-3" @click="openResidenceAttestation">
            <icon :data="icons.link" original width="20" height="20" />
          </div>
        </div><br>
        <div class="infos flex items-center">
          <div class="question text-lg mr-3">{{ $t('childrenBirthdayAct') }}</div>
          <div v-if="isJoinPieces.childrenBirthdayAct !== ''" class="answer cursor-pointer ml-3" @click="openChildrenBirthdayAct">
            <icon :data="icons.link" original width="20" height="20" />
          </div>
        </div><br>
        <div class="infos flex items-center">
          <div class="question text-lg mr-3">{{ $t('wifeBirthdayAct') }}</div>
          <div v-if="isJoinPieces.wifeBirthdayAct !== ''" class="answer cursor-pointer ml-3" @click="openWifeBirthdayAct">
            <icon :data="icons.link" original width="20" height="20" />
          </div>
        </div><br>
        <div class="infos flex items-center">
          <div class="question text-lg mr-3">{{ $t('weddingAct') }}</div>
          <div v-if="isJoinPieces.weddingAct !== ''" class="answer cursor-pointer ml-3" @click="openWeddingAct">
            <icon :data="icons.link" original width="20" height="20" />
          </div>
        </div><br>
        <div class="infos flex items-center">
          <div class="question text-lg mr-3">{{ $t('recommendationLetter') }}</div>
          <div v-if="isJoinPieces.recommendationLetter !== ''" class="answer cursor-pointer ml-3" @click="openRecommendationLetter">
            <icon :data="icons.link" original width="20" height="20" />
          </div>
        </div><br>
        <div class="infos flex items-center">
          <div class="question text-lg mr-3">{{ $t('criminalRecord') }}</div>
          <div v-if="isJoinPieces.criminalRecord !== ''" class="answer cursor-pointer ml-3" @click="openCriminalRecord">
            <icon :data="icons.link" original width="20" height="20" />
          </div>
        </div><br>
      </div>
    </div>
  </div>
</template>

<script>
import open from '../../../assets/icons/ic_open_up.svg'
import close from '../../../assets/icons/ic_open_down.svg'
import link from '../../../assets/icons/ic_link.svg'
export default {
  name: 'join-pieces',
  props: {
    isJoinPieces: Object
  },
  data () {
    return {
      icons: { open, close, link },
      isContent: false
    }
  },
  methods: {
    openBirthdayAct () {
      window.open(this.isJoinPieces.birthdayAct, '_blank')
    },
    openIdentityCard () {
      window.open(this.isJoinPieces.identityCard, '_blank')
    },
    openIdentityPicture () {
      window.open(this.isJoinPieces.identityPicture, '_blank')
    },
    openResidenceAttestation () {
      window.open(this.isJoinPieces.residenceAttestation, '_blank')
    },
    openChildrenBirthdayAct () {
      window.open(this.isJoinPieces.childrenBirthdayAct, '_blank')
    },
    openWifeBirthdayAct () {
      window.open(this.isJoinPieces.wifeBirthdayAct, '_blank')
    },
    openWeddingAct () {
      window.open(this.isJoinPieces.weddingAct, '_blank')
    },
    openRecommendationLetter () {
      window.open(this.isJoinPieces.recommendationLetter, '_blank')
    },
    openCriminalRecord () {
      window.open(this.isJoinPieces.criminalRecord, '_blank')
    },
    showContent () {
      this.isContent = !this.isContent
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "src/assets/styles/sass/variables";
  .svg-fill {
    fill: transparent;
  }
  .title {
    color: $team_blue;
  }
</style>
