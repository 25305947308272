<template>
  <div class="current-hands-lists">
    <div class="begin">
      <div class="content p-4 rounded mb-4 shadow">
        <div class="content-header flex items-center cursor-pointer" @click="showContent">
          <div class="action w-1/12">{{ currentHandId + 1 }}</div>
          <div class="action w-2/5 mr-4">
            <div class="hand-text">{{ event.title }}</div>
          </div>
          <div class="action w-2/5 mr-4">
            <div class="flex items-center">
              <div class="society-icon mr-4"><icon :data="icons.society" original width="15" height="15"/></div>
              <div class="society-name">{{ site.name }}</div>
            </div>
          </div>
          <div class="action w-1/3 mr-4 capitalize">
            {{ new Date(currentHandsList.dateTimeStamp).toLocaleDateString('FR', options) }}
          </div>
          <div class="icons">
            <icon v-if="isContent" :data="icons.open" original width="15" height="15" />
            <icon v-if="!isContent" :data="icons.close" original width="15" height="15" />
          </div>
        </div>
        <div v-if="isContent" class="content-body flex items-center mt-3">
          <div class="action w-1/12"></div>
          <div class="flex items-center w-2/5 mr-4">
            <div class="answer text-sm">{{ currentHandsList.event_description }}</div>
          </div>
          <div class="name w-2/5 mx-4">
            <div class="answer">
              <div class="flex items-center cursor-pointer" @click="goToImage">
                <div class="attach-icon mr-4"><icon :data="icons.attach" original width="15" height="15"/></div>
                <div v-if="currentHandsList.report.length > 0" class="attach-name text-xs">IMG20225587.jpeg</div>
                <div v-else class="attach-name text-xs">----</div>
              </div>
            </div>
          </div>
          <div class="society w-1/3 mr-4">
            <div
              v-if="currentHandsList.status === 'declared'"
              class="status-action"
              :style="{ color: '#4225FC' }"
            >
              {{ $t('declaredIncident') }}
            </div>
            <div
              v-if="currentHandsList.status === 'treated'"
              class="status-action"
              :style="{ color: '#009900' }"
            >
              {{ $t('treatedIncident') }}
            </div>
            <div
              v-if="currentHandsList.status === 'progress'"
              class="status-action"
              :style="{ color: '#FBA705' }"
            >
              {{ $t('goingIncident') }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import file from '../../../assets/icons/ic_file_hand.svg'
import society from '../../../assets/icons/ic_town.svg'
import open from '../../../assets/icons/ic_open_up.svg'
import close from '../../../assets/icons/ic_open_down.svg'
import attach from '../../../assets/icons/ic_attach.svg'
import { collection, query, where, getDocs } from 'firebase/firestore'
import { firestore } from '../../../main'
export default {
  name: 'current-hands-lists',
  props: {
    currentHandsList: {
      type: Object,
      default: function () {
        return {}
      }
    },
    agentName: String,
    currentHandId: Number
  },
  data () {
    return {
      icons: { file, society, open, close, attach },
      isContent: false,
      event: {},
      site: {},
      options: { year: 'numeric', month: 'short', day: '2-digit', hour: '2-digit', minute: '2-digit' }
    }
  },
  created () {
    this.getEventName()
    this.getSiteName()
    // console.log(this.currentHandsList)
  },
  methods: {
    goToImage () { // OPEN REPORT FILE
      window.open(this.currentHandsList.report, '_blank')
    },
    async getEventName () { // GET EVENT INFORMATION
      const q = query(collection(firestore, 'event'),
        where('uid', '==', this.currentHandsList.event))
      const querySnapshot = await getDocs(q)
      querySnapshot.forEach((doc) => {
        // console.log(doc.id, ' => ', doc.data())
        this.event = doc.data()
      })
    },
    async getSiteName () { // GET SITE INFORMATION
      const q = query(collection(firestore, 'sites'),
        where('uid', '==', this.currentHandsList.site_name))

      const querySnapshot = await getDocs(q)
      querySnapshot.forEach((doc) => {
        // console.log(doc.id, ' => ', doc.data())
        this.site = doc.data().identification
      })
    },
    showContent () {
      this.isContent = !this.isContent
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "src/assets/styles/sass/variables";
  .content {
    background: #F4F4F7;
  }
  .hand-text {
    color: #606060;
  }
  .attach-name {
    color: $team_indigo;
  }
  .svg-fill {
    fill: transparent;
  }
</style>
