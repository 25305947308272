<template>
  <div class="designated-site-agent">
    <div class="begin">
      <input
        v-model="search"
        type="text"
        placeholder="Ro |"
        class="ipt w-full border p-4 rounded-lg"
        @input="onChange"
      >
      <div
        v-show="isAgentList"
        class="countries absolute bg-white z-10 cursor-pointer max-h-64 overflow-y-scroll border w-72"
      >
        <div
          v-for="(result, i) in filterAgent"
          :key="i"
          class="select-agent px-4 py-2 text-lg z-50"
          @click="setResult(i)"
        >
          {{ result.identification.client + ' (' + result.identification.name + ')' }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { collection, getDocs, query, where } from 'firebase/firestore'
import { firestore } from '@/main'
export default {
  name: 'designated-site-agent',
  props: {
    actualSite: Object
  },
  data () {
    return {
      isAgentList: false,
      optionsAgent: [],
      search: ''
    }
  },
  computed: {
    filterAgent: function () {
      let back = this.optionsAgent
      if (this.search !== '') {
        let authorNameSearchString = this.search
        authorNameSearchString = authorNameSearchString.toLowerCase()
        back = back.filter(function (item) {
          if ((item.identification.name.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase().indexOf(authorNameSearchString.normalize('NFD').replace(/[\u0300-\u036f]/g, '')) !== -1) ||
            (item.identification.client.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase().indexOf(authorNameSearchString.normalize('NFD').replace(/[\u0300-\u036f]/g, '')) !== -1)) {
            // console.log(item)
            return item
          }
        })
      }
      return back
    }
  },
  created () {
    this.getAllAgents()
  },
  methods: {
    onChange () {
      this.isAgentList = true
    },
    setResult (index) { // SET SEARCH AGENT RESULT
      if (this.search === '') {
        this.isAgentList = false
      } else {
        this.search = this.filterAgent[index].identification.name
        this.$emit('updateAgent', this.filterAgent[index].uid)
        this.isAgentList = false
      }
    },
    async getAllAgents () { // GET ALL AGENT LIST
      const q = query(collection(firestore, 'sites'),
        where('uid', '!=', this.actualSite.uid))
      const querySnapshot = await getDocs(q)
      querySnapshot.forEach((doc) => {
        // console.log(doc.id, ' => ', doc.data())
        this.optionsAgent.push(doc.data())
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "src/assets/styles/sass/variables";
  .ipt {
    background: $team_gray_popup;
  }
  .select-agent:hover {
    background: rgb(243 244 246);
  }
  .countries {
    width: 19rem;
  }
</style>
