<template>
  <div class="add-agents">
    <div class="begin">
      <div class="header flex items-center justify-between mb-12">
        <div class="title-bloc flex items-center">
          <div class="icons mr-4 cursor-pointer" @click="goBack"><icon :data="icons.back" original width="40" height="40"/></div>
          <div class="title font-semibold text-2xl">{{ $t('newAgent') }}</div>
        </div>
      </div>
      <div class="body">
        <div class="body-content bg-white p-8">
          <div class="first-title font-medium text-xl">{{ $t('identificationInformation') }}</div>
          <div class="input-bloc my-6">
            <div class="ipt w-auto rounded-full">
              <div v-if="fileName === ''" class="pic p-12 cursor-pointer" @click="choosePicture">
                <icon :data="icons.picture" original width="50" height="50"/>
              </div>
              <div v-else class="profile">
                <img :src="fileName" class="w-36 h-36 rounded-full">
              </div>
            </div>
          </div>
          <div class="identification mb-8">
            <add-identification
              :is-agent-details="agentsDetailsBack"
              @sendName="sendName"
              @sendBirthday="sendBirthday"
              @sendPhone="sendPhone"
              @sendEmail="sendEmail"
              @sendCity="sendCity"
              @sendAddress="sendAddress"
              @sendBirthdayAct="sendBirthdayAct"
              @sendIdentityCard="sendIdentityCard"
              @sendIdentityPicture="sendIdentityPicture"
              @sendResidenceAttestation="sendResidenceAttestation"
              @sendChildrenBirthdayAct="sendChildrenBirthdayAct"
              @sendWifeBrithdayAct="sendWifeBirthdayAct"
              @sendWeddingAct="sendWeddingAct"
              @sendRecommendationLetter="sendRecommendationLetter"
              @sendCriminalRecord="sendCriminalRecord"
            />
          </div>
          <div class="first-title font-medium text-xl">{{ $t('contractualInformation') }}</div>
          <div class="contractual mb-8 mt-6">
            <add-contractual
              :is-agent-details="agentsDetailsBack"
              @sendAgentFunction="sendAgentFunction"
              @sendDate="sendDate"
              @sendType="sendType"
              @sendTerm="sendTerm"
              @sendSalary="sendSalary"
              @sendSupervisorZone="sendSupervisorZone"
            />
          </div>
          <div v-if="error" class="error text-center text-red-500">{{ $t('errorAddAgent') }}</div><br>
          <div class="save-button">
            <button-base
              :label="$t('saveButton')"
              :is-loading="loader"
              @click="saveAgent"
            />
          </div>
          <div class="loading" :class="{ 'not_empty': loading === false }">
            <loading
              :active="loading"
              :can-cancel="false"
              :on-cancel="onCancel"
              :is-full-page="true"
              background-color="rgba(0,0,0,0.15)"
              color="#324971"
              loader="dots"
            ></loading>
          </div>
        </div>
      </div>
    </div>
    <success-popup-base
      v-if="isSuccess"
      :success-description="successMessage"
      @closeSuccess="closeSuccess"
    />
  </div>
</template>

<script>
import back from '../../../assets/icons/ic_go_back.svg'
import AddIdentification from './add-identification'
import picture from '../../../assets/icons/ic_add_picture.svg'
import AddContractual from './add-contractual'
import ButtonBase from '../add/button-base'
import http from '../../../plugins/https'
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage'
import { doc, setDoc, updateDoc } from 'firebase/firestore'
import { firestore } from '../../../main'
import SuccessPopupBase from '../add/success-popup-base'
import Loading from 'vue3-loading-overlay'
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css'
export default {
  name: 'add-agents',
  components: { Loading, SuccessPopupBase, ButtonBase, AddContractual, AddIdentification },
  props: {
    agentsDetailsBack: Object
  },
  data () {
    return {
      icons: { back, picture },
      file: '',
      fileName: '',
      isIdentification: {
        name: '',
        phone: null,
        email: '',
        city: '',
        address: '',
        birthdayAct: '',
        identityCard: '',
        identityPicture: '',
        residenceAttestation: '',
        childrenBirthdayAct: '',
        wifeBirthdayAct: '',
        weddingAct: '',
        recommendationLetter: '',
        criminalRecord: ''
      },
      isContractual: {
        agentFunction: '',
        date: '',
        type: '',
        salary: null
      },
      isZone: '',
      error: false,
      loader: false,
      uploadedLink: '',
      uploadedIdentityCard: '',
      uploadedIdentityPicture: '',
      uploadedResidenceAttestation: '',
      uploadedChildrenActs: '',
      uploadedWifeAct: '',
      uploadedWeddingAct: '',
      uploadedLetter: '',
      uploadedCriminal: '',
      uploadedProfile: '',
      isSuccess: false,
      loading: false,
      successMessage: ''
    }
  },
  methods: {
    sendSupervisorZone (answer) {
      this.isZone = answer
    },
    closeSuccess (val) {
      this.isSuccess = val
      this.loading = false
    },
    async saveAgent () { // ADD NEW AGENT
      const vm = this
      if (this.isIdentification.name === '' || this.isIdentification.phone === '' || this.isIdentification.city === '' || this.isIdentification.address === '' || this.isContractual.agentFunction === '' || this.isContractual.date === '' || this.isContractual.type === '' || this.isContractual.salary === '') {
        this.error = true
      } else {
        this.error = false
        this.loading = true
        if (Object.keys(this.agentsDetailsBack).length === 0) { // ADD NEW AGENT
          let matricule = ''
          if (this.isContractual.agentFunction === this.$t('centralPersonalAdministration')) {
            matricule = 'CS' + '-' + Math.random().toString(8).substr(2, 7) + this.isIdentification.name.charAt(0).toUpperCase()
          } else {
            matricule = this.isContractual.agentFunction.substring(0, 2).toUpperCase() + '-' + Math.random().toString(8).substr(2, 7) + this.isIdentification.name.charAt(0).toUpperCase()
          }
          // console.log(matricule)
          const params = new URLSearchParams()
          const agentEmail = matricule + '@teamsecu.com'
          params.append('email', agentEmail)
          http.post('https://us-central1-teamsecu-f002b.cloudfunctions.net/exampleCreate', params)
            .then(async response => {
              // console.log(response)
              // console.log(response.uid)
              const id = response.uid
              // console.log(id)

              // CREATE AGENT INFORMATION
              // const updateAgent = doc(firestore, 'agents', id)
              await setDoc(doc(firestore, 'agents', id), {
                uid: id,
                createdAt: new Date().getTime(),
                name: this.isIdentification.name,
                matricule: matricule,
                profilePicture: vm.uploadedProfile,
                email: this.isIdentification.email,
                birthday: new Date(this.isIdentification.birthday).getTime(),
                phone: this.isIdentification.phone,
                city: this.isIdentification.city,
                address: this.isIdentification.address,
                agentFunction: this.isContractual.agentFunction,
                hiringDate: new Date(this.isContractual.date).getTime(),
                contractType: this.isContractual.type,
                contractTerm: new Date(this.isContractual.term).getTime(),
                salary: this.isContractual.salary,
                addedBy: this.$store.getters.userId,
                birthdayAct: vm.uploadedLink,
                identityCard: vm.uploadedIdentityCard,
                identityPicture: vm.uploadedIdentityPicture,
                residenceAttestation: vm.uploadedResidenceAttestation,
                childrenBirthdayAct: vm.uploadedChildrenActs,
                wifeBirthdayAct: vm.uploadedWifeAct,
                weddingAct: vm.uploadedWeddingAct,
                recommendationLetter: vm.uploadedLetter,
                criminalRecord: vm.uploadedCriminal,
                takeServiceId: '',
                status: 'active',
                isDesignated: false,
                haveEquipment: false,
                numberAffected: 0
              }).then(res => {
                console.log('Created successful:' + res)
              }).catch(err => {
                console.log(err)
              })

              // UPLOAD FILES TO STORAGE AND GET LINK
              await this.uploadFile(id)
              await this.uploadIdentityCard(id)
              await this.uploadIdentityPicture(id)
              await this.uploadResidenceAttestation(id)
              await this.uploadChildrenBirthdayAct(id)
              await this.uploadWifeBirthdayAct(id)
              await this.uploadWeddingAct(id)
              await this.uploadRecommendationLetter(id)
              await this.uploadCriminalRecord(id)
              await this.uploadProfilePicture(id)
              this.successMessage = this.$t('successAgentCreated')
              this.isSuccess = true
            }).catch(error => {
              console.log(error)
            })
        } else { // UPDATE AGENT INFORMATION
          const updateAgents = doc(firestore, 'agents', this.agentsDetailsBack.uid)
          const data = {
            updatedAt: new Date().getTime(),
            updatedBy: this.$store.getters.userId,
            name: this.isIdentification.name,
            email: this.isIdentification.email,
            birthday: new Date(this.isIdentification.birthday).getTime(),
            phone: this.isIdentification.phone,
            city: this.isIdentification.city,
            address: this.isIdentification.address,
            agentFunction: this.isContractual.agentFunction,
            hiringDate: new Date(this.isContractual.date).getTime(),
            contractType: this.isContractual.type,
            contractTerm: new Date(this.isContractual.term).getTime(),
            salary: this.isContractual.salary
          }
          await updateDoc(updateAgents, data)

          // UPLOAD FILES TO STORAGE AND GET LINK
          await this.uploadFile(vm.agentsDetailsBack.uid)
          await this.uploadIdentityCard(vm.agentsDetailsBack.uid)
          await this.uploadIdentityPicture(vm.agentsDetailsBack.uid)
          await this.uploadResidenceAttestation(vm.agentsDetailsBack.uid)
          await this.uploadChildrenBirthdayAct(vm.agentsDetailsBack.uid)
          await this.uploadWifeBirthdayAct(vm.agentsDetailsBack.uid)
          await this.uploadWeddingAct(vm.agentsDetailsBack.uid)
          await this.uploadRecommendationLetter(vm.agentsDetailsBack.uid)
          await this.uploadCriminalRecord(vm.agentsDetailsBack.uid)
          await this.uploadProfilePicture(vm.agentsDetailsBack.uid)
          this.successMessage = this.$t('successAgentUpdated')
          this.isSuccess = true
        }
      }
    },
    uploadFile (link) {
      const vm = this
      if (vm.isIdentification.birthdayAct !== '') {
        return new Promise((resolve, reject) => {
          const storage = getStorage()
          const storageRef = ref(storage, 'agents/birthdayAct/' + link)
          const uploadTask = uploadBytesResumable(storageRef, this.isIdentification.birthdayAct)
          uploadTask.on('state_changed',
            (snapshot) => {
              const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
              console.log('Upload is ' + progress + '% done')
            },
            (error) => {
              console.log(error.code)
            },
            () => {
              getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
                console.log('File available at', downloadURL)
                const updateAgent = doc(firestore, 'agents', link)
                await updateDoc(updateAgent, {
                  birthdayAct: downloadURL
                })
                resolve('completed')
              })
            })
        })
      }
    },
    uploadIdentityCard (link) {
      const vm = this
      if (vm.isIdentification.identityCard !== '') {
        return new Promise((resolve, reject) => {
          const storage = getStorage()
          const storageRef = ref(storage, 'agents/identityCard/' + link)
          const uploadTask = uploadBytesResumable(storageRef, this.isIdentification.identityCard)
          uploadTask.on('state_changed',
            (snapshot) => {
              const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
              console.log('Upload is ' + progress + '% done')
            },
            (error) => {
              console.log(error.code)
            },
            () => {
              getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
                console.log('File available at', downloadURL)
                const updateAgent = doc(firestore, 'agents', link)
                await updateDoc(updateAgent, {
                  identityCard: downloadURL
                })
                resolve('completed')
              })
            })
        })
      }
    },
    uploadIdentityPicture (link) {
      const vm = this
      if (vm.isIdentification.identityPicture !== '') {
        return new Promise((resolve, reject) => {
          const storage = getStorage()
          const storageRef = ref(storage, 'agents/identityPicture/' + link)
          const uploadTask = uploadBytesResumable(storageRef, this.isIdentification.identityPicture)
          uploadTask.on('state_changed',
            (snapshot) => {
              const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
              console.log('Upload is ' + progress + '% done')
            },
            (error) => {
              console.log(error.code)
            },
            () => {
              getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
                console.log('File available at', downloadURL)
                const updateAgent = doc(firestore, 'agents', link)
                await updateDoc(updateAgent, {
                  identityPicture: downloadURL
                })
                resolve('completed')
              })
            })
        })
      }
    },
    uploadResidenceAttestation (link) {
      const vm = this
      if (vm.isIdentification.residenceAttestation !== '') {
        return new Promise((resolve, reject) => {
          const storage = getStorage()
          const storageRef = ref(storage, 'agents/residenceAttestation/' + link)
          const uploadTask = uploadBytesResumable(storageRef, this.isIdentification.residenceAttestation)
          uploadTask.on('state_changed',
            (snapshot) => {
              const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
              console.log('Upload is ' + progress + '% done')
            },
            (error) => {
              console.log(error.code)
            },
            () => {
              getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
                console.log('File available at', downloadURL)
                const updateAgent = doc(firestore, 'agents', link)
                await updateDoc(updateAgent, {
                  residenceAttestation: downloadURL
                })
                resolve('completed')
              })
            })
        })
      }
    },
    uploadChildrenBirthdayAct (link) {
      const vm = this
      if (vm.isIdentification.childrenBirthdayAct !== '') {
        return new Promise((resolve, reject) => {
          const storage = getStorage()
          const storageRef = ref(storage, 'agents/childrenBirthdayAct/' + link)
          const uploadTask = uploadBytesResumable(storageRef, this.isIdentification.childrenBirthdayAct)
          uploadTask.on('state_changed',
            (snapshot) => {
              const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
              console.log('Upload is ' + progress + '% done')
            },
            (error) => {
              console.log(error.code)
            },
            () => {
              getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
                console.log('File available at', downloadURL)
                const updateAgent = doc(firestore, 'agents', link)
                await updateDoc(updateAgent, {
                  childrenBirthdayAct: downloadURL
                })
                resolve('completed')
              })
            })
        })
      }
    },
    uploadWifeBirthdayAct (link) {
      const vm = this
      if (vm.isIdentification.wifeBirthdayAct !== '') {
        return new Promise((resolve, reject) => {
          const storage = getStorage()
          const storageRef = ref(storage, 'agents/wifeBirthdayAct/' + link)
          const uploadTask = uploadBytesResumable(storageRef, this.isIdentification.wifeBirthdayAct)
          uploadTask.on('state_changed',
            (snapshot) => {
              const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
              console.log('Upload is ' + progress + '% done')
            },
            (error) => {
              console.log(error.code)
            },
            () => {
              getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
                console.log('File available at', downloadURL)
                const updateAgent = doc(firestore, 'agents', link)
                await updateDoc(updateAgent, {
                  wifeBirthdayAct: downloadURL
                })
                resolve('completed')
              })
            })
        })
      }
    },
    uploadWeddingAct (link) {
      const vm = this
      if (vm.isIdentification.weddingAct !== '') {
        return new Promise((resolve, reject) => {
          const storage = getStorage()
          const storageRef = ref(storage, 'agents/weddingAct/' + link)
          const uploadTask = uploadBytesResumable(storageRef, this.isIdentification.weddingAct)
          uploadTask.on('state_changed',
            (snapshot) => {
              const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
              console.log('Upload is ' + progress + '% done')
            },
            (error) => {
              console.log(error.code)
            },
            () => {
              getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
                console.log('File available at', downloadURL)
                const updateAgent = doc(firestore, 'agents', link)
                await updateDoc(updateAgent, {
                  weddingAct: downloadURL
                })
                resolve('completed')
              })
            })
        })
      }
    },
    uploadRecommendationLetter (link) {
      const vm = this
      if (vm.isIdentification.recommendationLetter !== '') {
        return new Promise((resolve, reject) => {
          const storage = getStorage()
          const storageRef = ref(storage, 'agents/recommendationLetter/' + link)
          const uploadTask = uploadBytesResumable(storageRef, this.isIdentification.recommendationLetter)
          uploadTask.on('state_changed',
            (snapshot) => {
              const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
              console.log('Upload is ' + progress + '% done')
            },
            (error) => {
              console.log(error.code)
            },
            () => {
              getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
                console.log('File available at', downloadURL)
                const updateAgent = doc(firestore, 'agents', link)
                await updateDoc(updateAgent, {
                  recommendationLetter: downloadURL
                })
                resolve('completed')
              })
            })
        })
      }
    },
    uploadCriminalRecord (link) {
      const vm = this
      if (vm.isIdentification.criminalRecord !== '') {
        return new Promise((resolve, reject) => {
          const storage = getStorage()
          const storageRef = ref(storage, 'agents/criminalRecord/' + link)
          const uploadTask = uploadBytesResumable(storageRef, this.isIdentification.criminalRecord)
          uploadTask.on('state_changed',
            (snapshot) => {
              const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
              console.log('Upload is ' + progress + '% done')
            },
            (error) => {
              console.log(error.code)
            },
            () => {
              getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
                console.log('File available at', downloadURL)
                const updateAgent = doc(firestore, 'agents', link)
                await updateDoc(updateAgent, {
                  criminalRecord: downloadURL
                })
                resolve('completed')
              })
            })
        })
      }
    },
    sendAgentFunction (val) {
      this.isContractual.agentFunction = val
    },
    sendDate (val) {
      this.isContractual.date = val
    },
    sendType (val) {
      this.isContractual.type = val
    },
    sendTerm (val) {
      this.isContractual.term = val
    },
    sendSalary (val) {
      this.isContractual.salary = val
    },
    sendName (val) {
      this.isIdentification.name = val
    },
    sendBirthday (val) {
      this.isIdentification.birthday = val
      // console.log(this.isIdentification.birthday.substring(8))
    },
    sendPhone (val) {
      this.isIdentification.phone = val
    },
    sendEmail (val) {
      this.isIdentification.email = val
    },
    sendCity (val) {
      this.isIdentification.city = val
    },
    sendAddress (val) {
      this.isIdentification.address = val
    },
    sendBirthdayAct (val) {
      this.isIdentification.birthdayAct = val
    },
    sendIdentityCard (val) {
      this.isIdentification.identityCard = val
    },
    sendIdentityPicture (val) {
      this.isIdentification.identityPicture = val
    },
    sendResidenceAttestation (val) {
      this.isIdentification.residenceAttestation = val
    },
    sendChildrenBirthdayAct (val) {
      this.isIdentification.childrenBirthdayAct = val
    },
    sendWifeBirthdayAct (val) {
      this.isIdentification.wifeBirthdayAct = val
    },
    sendWeddingAct (val) {
      this.isIdentification.weddingAct = val
    },
    sendRecommendationLetter (val) {
      this.isIdentification.recommendationLetter = val
    },
    sendCriminalRecord (val) {
      this.isIdentification.criminalRecord = val
    },
    uploadProfilePicture (link) {
      const vm = this
      if (vm.file !== '') {
        return new Promise((resolve, reject) => {
          const storage = getStorage()
          const storageRef = ref(storage, 'agents/profilePicture/' + link)
          const uploadTask = uploadBytesResumable(storageRef, this.file)
          uploadTask.on('state_changed',
            (snapshot) => {
              const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
              console.log('Upload is ' + progress + '% done')
            },
            (error) => {
              console.log(error.code)
            },
            () => {
              getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
                console.log('File available at', downloadURL)
                const updateAgent = doc(firestore, 'agents', link)
                await updateDoc(updateAgent, {
                  profilePicture: downloadURL
                })
                resolve('completed')
              })
            })
        })
      }
    },
    choosePicture () { // CHOOSE PROFILE PICTURE
      const input = document.createElement('input')
      input.type = 'file'
      input.setAttribute('accept', 'image/*')
      input.onchange = e => {
        this.file = e.target.files[0]
        // this.url = URL.createObjectURL(this.file)
        const reader = new FileReader()
        reader.readAsDataURL(this.file)
        reader.onload = a => {
          this.fileName = reader.result
        }
      }
      input.click()
    },
    onCancel () {
      console.log('User cancelled the loader.')
    },
    goBack () {
      this.$emit('goBackIndex', false)
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "src/assets/styles/sass/variables";
  .svg-fill {
    fill: transparent;
  }
  .ipt {
    background: $team_gray_popup;
    width: fit-content;
  }
  .save-button::v-deep {
    .button-base {
      .page-button-real {
        border-radius: .25rem;
        width: 33.33%;
        h4 {
          font-size: 1.25rem;
        }
      }
    }
  }
</style>
