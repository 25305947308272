<template>
  <div class="request-lists">
    <div class="begin">
      <div class="content rounded-xl p-4 mb-4 border">
        <div class="object-bloc">
          <span class="object-title">{{ $t('object') }} : </span>
          <span class="object-answer">{{ agentsRequests.object }}</span>
        </div><br>
        <div class="description w-11/12">
          {{ agentsRequests.requests_description }}
        </div><br>
        <div class="fin flex items-center justify-between">
          <div class="flex items-center cursor-pointer" @click="openLink">
            <div class="attach-icon mr-4"><icon :data="icons.attach" original width="20" height="20"/></div>
            <div class="attach-name">consigne.pdf</div>
          </div>
          <div class="date capitalize">
            {{ 'Date : ' + new Date(agentsRequests.createdAt).toLocaleDateString('FR', options) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import attach from '../../../assets/icons/ic_attach.svg'
export default {
  name: 'request-lists',
  props: {
    agentsRequests: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data () {
    return {
      icons: { attach },
      options: { year: 'numeric', month: 'short', day: '2-digit' }
    }
  },
  methods: {
    openLink () {
      window.open(this.agentsRequests.attachment, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "src/assets/styles/sass/variables";
  .content {
    background: #F5F5F5;
  }
  .object-title {
    color: #8E8EA9;
  }
  .description {
    color: #5B5B5B;
  }
  .attach-name {
    color: $team_indigo;
  }
  .date {
    color: #909090;
  }
</style>
