<template>
  <div class="agents">
    <div class="begin pb-12">
      <!-- INDEX PAGE -->
      <div v-if="!isAddAgent && !isAgentDetails" class="principal">
        <div class="header">
          <!-- HEADER POPUP -->
          <the-header
            :file-button="$t('importListsButton')"
            :add-button="$t('addAgentButton')"
            :numbers="sortedArrayAgents.length"
            :show-agent-filter="true"
            @clickGreenButton="clickGreenButton"
            @clickBlueButton="clickBlueButton"
            @searchForItems="searchForItems"
            @sendAgentFunction="sendAgentFunction"
          />
          <!-- HEADER POPUP END -->
        </div>

        <div class="body">
          <div class="header-table flex items-center p-4 bg-white rounded-xl border font-medium text-lg">
            <div class="action w-1/12">
              <checkbox-base :check="allCheck" @click="allCheckChange"/>
            </div>
            <div class="action w-2/3 mr-4">{{ $t('completeName') }}</div>
            <div class="action w-1/3 mr-4">{{ $t('contact') }}</div>
            <div class="action w-1/3 mr-4">{{ $t('registrationNumber') }}</div>
            <div class="action w-1/3 mr-4">{{ $t('hiringDate') }}</div>
            <div class="action w-1/3 mr-4 relative">
              <div class="flex items-center">
                <div class="mr-2">{{ $t('status') }}</div>
                <div class="cursor-pointer" @click="statusFilter = !statusFilter">
                  <icon :data="icons.dropdown" original width="25" height="25" />
                </div>
                <div class="text-base">{{ chosenStatus }}</div>
              </div>
              <div v-if="statusFilter" class="filter-by absolute bg-white shadow-2xl right-0 w-64 z-10 cursor-pointer">
                <div
                  v-for="(item, index) in allStatus"
                  :key="index"
                  class="option-list px-6 py-3 border-b"
                  @click="selectStatusFilter(index)"
                >
                  {{ item }}
                </div>
              </div>
            </div>
            <div class="action w-1/12"></div>
          </div>
          <div class="header-body">
            <div class="loading" :class="{ 'not_empty': loading === false }">
              <loading
                :active="loading"
                :can-cancel="false"
                :on-cancel="onCancel"
                :is-full-page="fullPage"
                background-color="#FFFFFF"
                color="#324971"
                loader="dots"
              ></loading>
            </div>
            <div
              v-if="nothing"
              class="no-student text-center text-xl text-gray-500 py-32"
            >
              {{ $t('noAgentAdded') }}
            </div>
            <div
              v-for="(item, index) in sortedArrayAgents"
              :key="index"
            >
              <agents-lists
                :agents-lists="item"
                :is-check="isAllChecked"
                @isJustChecked="isJustChecked"
                @isJustNotChecked="isJustNotChecked"
                @click="openAgentDetails(index)"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- ADD NEW AGENT PAGE -->
      <add-agents
        v-if="isAddAgent"
        :agents-details-back="agentsDetails"
        @goBackIndex="goBackIndex"
      />
      <!-- AGENT DETAILS PAGE -->
      <agent-details
        v-if="isAgentDetails"
        :agents-details="agentsDetails"
        @goBackToIndex="goBackToIndex"
        @editAgentProfile="editAgentProfile"
      />
    </div>
    <!-- IMPORT AGENT LIST EXCEL POPUP -->
    <import-list
      v-if="isImportList"
      @closeImport="closeImport"
      @openSuccessImport="openSuccessImport"
    />
    <!-- SUCCESS IMPORT SUCCESS MODAL -->
    <success-popup-base
      v-if="isSuccessImport"
      :success-description="$t('agentImportedSuccess')"
      @closeSuccess="closeSuccessImport"
    />
  </div>
</template>

<script>
import TheHeader from '../../components/the-header'
import ImportList from '../../components/helper/agents/import-list'
import CheckboxBase from '../../components/helper/add/checkbox-base'
import AgentsLists from '../../components/helper/agents/agents-lists'
import AddAgents from '../../components/helper/agents/add-agents'
import AgentDetails from '../../components/helper/agents/agent-details'
import { collection, query, orderBy, onSnapshot, where, doc, deleteDoc } from 'firebase/firestore'
import { firestore } from '../../main'
import Loading from 'vue3-loading-overlay'
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css'
import dropdown from '../../assets/icons/ic_gridicons_dropdown.svg'
import SuccessPopupBase from '@/components/helper/add/success-popup-base'
import districts from '@/components/helper/add/district-base'
export default {
  name: 'index',
  components: { SuccessPopupBase, Loading, AgentDetails, AddAgents, AgentsLists, CheckboxBase, ImportList, TheHeader },
  data () {
    return {
      icons: { dropdown },
      isImportList: false,
      allAgents: [],
      isAllChecked: true,
      selectedInMatch: [],
      isNotSelected: [],
      allCheck: true,
      isAddAgent: false,
      isAgentDetails: false,
      loading: true,
      fullPage: false,
      nothing: false,
      agentsDetails: {},
      searching: '',
      agentFilter: '',
      statusFilter: false,
      status: '',
      chosenStatus: '',
      isSuccessImport: false,
      chief: [],
      allTown: districts,
      allStatus: [this.$t('reservist'), this.$t('active'), this.$t('inService'), this.$t('resting'), this.$t('suspended'), this.$t('onLeave'), this.$t('out'), this.$t('all')]
    }
  },
  created () {
    this.getAllAgentsLists()
  },
  computed: {
    sortedArrayAgents: function () {
      let sorted = []
      for (let i = 0; i < this.allAgents.length; i++) {
        if (this.allAgents[i].status === this.status) {
          sorted.push(this.allAgents[i])
        }
      }
      let search = ''
      if (this.status !== '') {
        search = this.status
      } else {
        sorted = this.allAgents
      }
      if (this.agentFilter !== '') {
        search = this.agentFilter
      }
      if (this.searching !== '') {
        search = this.searching
      }
      if (search !== '') {
        let authorNameSearchString = search
        authorNameSearchString = authorNameSearchString.toLowerCase()
        sorted = sorted.filter(function (item) {
          if ((item.name !== null && (item.name.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()).indexOf(authorNameSearchString.normalize('NFD').replace(/[\u0300-\u036f]/g, '')) !== -1) ||
            (item.agentFunction.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()).indexOf(authorNameSearchString.normalize('NFD').replace(/[\u0300-\u036f]/g, '')) !== -1 ||
            (item.status.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()).indexOf(authorNameSearchString.normalize('NFD').replace(/[\u0300-\u036f]/g, '')) !== -1) {
            // console.log(item)
            return item
          }
        })
      }
      return sorted
    }
  },
  methods: {
    async updateControllers () {
      for (const i in this.allAgents) {
        if (this.allAgents[i].agentFunction === 'agent') {
          await deleteDoc(doc(firestore, 'agents', this.allAgents[i].uid))
          console.log(i, this.allAgents[i].uid)
        }
      }
    },
    openSuccessImport (val) {
      this.isSuccessImport = val
      this.isImportList = false
    },
    closeSuccessImport (val) { // CLOSE SUCCESS IMPORT SITE LIST
      this.isSuccessImport = val
      window.location.reload()
    },
    async getAllAgentsLists () { // GET ALL AGENTS LIST
      const q = query(collection(firestore, 'agents'),
        where('agentFunction', '!=', 'administrative'),
        orderBy('agentFunction'),
        orderBy('createdAt', 'desc'))
      onSnapshot(q, (querySnapshot) => {
        const cities = []
        querySnapshot.forEach((doc) => {
          // console.log(doc.data())
          cities.push(doc.data())
          this.allAgents = cities
          // console.log(this.allAgents)
          this.allAgents.sort((x, y) => {
            if (x.name < y.name) { return -1 }
            if (x.name > y.name) { return 1 }
            return 0
          })
        })
        if (this.allAgents.length === 0) {
          this.loading = false
          this.nothing = true
        } else {
          this.loading = false
          this.nothing = false
        }
      })
    },
    async selectStatusFilter (index) { // FILTER LIST BY STATUS
      this.statusFilter = false
      this.chosenStatus = this.allStatus[index]
      if (index === 0) {
        this.status = 'reserved'
      } else if (index === 1) {
        this.status = 'active'
      } else if (index === 2) {
        this.status = 'inService'
      } else if (index === 3) {
        this.status = 'outService'
      } else if (index === 4) {
        this.status = 'suspended'
      } else if (index === 5) {
        this.status = 'onVacation'
      } else if (index === 6) {
        this.status = 'out'
      } else if (index === 7) {
        this.status = ''
      }
      // console.log(this.status)
    },
    sendAgentFunction (val) { // SELECT AGENT FILTER
      this.agentFilter = val
    },
    editAgentProfile (val) { // OPEN ADD AGENT TO EDIT SOME INFORMATION BASED ON AGENT PROFILE
      this.agentsDetails = val
      this.isAddAgent = true
    },
    searchForItems (val) { // GET INPUT SEARCH
      this.searching = val
    },
    goBackToIndex (val) { // CLOSE AGENT DETAILS PAGE
      this.isAgentDetails = val
    },
    openAgentDetails (index) { // OPEN AGENT DETAILS PAGE
      this.isAgentDetails = true
      this.agentsDetails = this.sortedArrayAgents[index]
    },
    goBackIndex (val) { // GO BACK TO INDEX
      this.isAddAgent = val
    },
    clickBlueButton (val) { // CLICK ON BLUE BUTTON TO OPEN ADD NEW AGENT PAGE
      this.isAddAgent = val
    },
    closeImport (val) { // CLOSE IMPORT AGENT LIST POPUP
      this.isImportList = val
    },
    clickGreenButton (val) { // CLICK ON GREEN BUTTON TO OPEN IMPORT AGENT LIST POPUP
      this.isImportList = val
    },
    allCheckChange () {
      this.isAllChecked = this.allCheck
    },
    onCancel () {
      console.log('User cancelled the loader.')
    },
    isJustChecked (answer) {
      this.allCheck = false
      this.selectedInMatch.push(answer)
    },
    isJustNotChecked (answer) {
      for (let i = 0; i < this.selectedInMatch.length; i++) {
        if (this.selectedInMatch[i] === answer) {
          this.selectedInMatch.splice(i, 1)
        }
      }
      this.allCheck = false
      this.isNotSelected.push(answer)
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "src/assets/styles/sass/variables";
  .action {
    color: $team_blue;
  }
  .loading {
    position: relative;
    height: 10vh;
  }
  .not_empty {
    height: auto;
  }
  .option-list:hover {
    background: rgb(243 244 246);
  }
</style>
