<template>
  <div class="add-affect-equipment">
    <popup-base>
      <div class="begin bg-white w-3/5 m-auto border">
        <div class="header p-8 flex items-center justify-between">
          <div class="title-bloc">
            <div class="title text-2xl font-medium">{{ $t('affectAnEquipment') }}</div>
          </div>
          <div class="close cursor-pointer" @click="closeAdd">
            <icon :data="icons.close" original width="25" height="25"/>
          </div>
        </div>
        <div class="body p-8">
          <div class="form py-6">
            <div class="login-form">
              <div class="label mb-2">{{ $t('equipmentsMenu') }}</div>
              <div class="input-bloc">
                <select-affect-equipment
                  :status-list="optionsName"
                  @selectedValue="selectedValue"
                />
              </div>
            </div><br>
            <div class="login-form">
              <div class="label mb-2">{{ $t('quantity') }}</div>
              <div class="input-bloc">
                <input
                  v-model="quantity"
                  type="number"
                  min="0"
                  class="ipt w-full p-4 border rounded-lg"
                  @keypress="isNumberOnly"
                >
              </div>
            </div><br>
            <div class="login-form">
              <div class="label mb-2">{{ $t('affectationDate') }}</div>
              <div class="input-bloc">
                <input
                  v-model="date"
                  type="date"
                  class="ipt w-full p-4 border rounded-lg"
                  @keypress="isNumberOnly"
                >
              </div>
            </div>
          </div>
          <div
            v-if="error"
            class="error-text text-red-500 text-center"
          >
            {{ $t('superiorQuantityError') }}
          </div><br>
          <div class="login-button flex items-center justify-end">
            <div class="cancel-button mr-3">
              <button-base
                :label="$t('cancelButton')"
                fontcolor="#909090"
                background="#FFFFFF"
                @click="closeAdd"
              />
            </div>
            <div class="valid-button ml-3">
              <button-base
                :label="$t('affectButton')"
                :is-loading="loader"
                @click="saveAffected"
              />
            </div>
          </div>
        </div>
      </div>
    </popup-base>
  </div>
</template>

<script>
import PopupBase from '../add/popup-base'
import close from '../../../assets/icons/ic_close.svg'
import ButtonBase from '../add/button-base'
import { collection, query, where, getDocs, doc, setDoc, updateDoc, orderBy } from 'firebase/firestore'
import { firestore } from '../../../main'
import SelectAffectEquipment from './select-affect-equipment'
export default {
  name: 'add-affect-equipment',
  components: { SelectAffectEquipment, ButtonBase, PopupBase },
  props: {
    detailsAgent: Object
  },
  data () {
    return {
      icons: { close },
      quantity: 0,
      date: '',
      optionsName: [],
      trieOption: null,
      loader: false,
      error: false
    }
  },
  created () {
    this.getAllEquipments()
  },
  methods: {
    async saveAffected () { // AFFECT EQUIPMENT TO AGENT
      if (this.trieOption !== null || this.quantity > 0) {
        if (this.quantity > this.trieOption.isLeft) {
          this.error = true
        } else {
          this.error = false
          this.loader = true
          const newAffectedEquipment = doc(collection(firestore, 'affected'))
          const data = {
            uid: newAffectedEquipment.id,
            quantity: this.quantity,
            affectedDate: new Date(this.date).getTime(),
            equipmentId: this.trieOption.uid,
            agentId: this.detailsAgent.uid,
            createdAt: new Date().getTime(),
            addedBy: this.$store.getters.userId
          }
          await setDoc(newAffectedEquipment, data)

          // UPDATE EQUIPMENT INFORMATION
          const equipmentRef = doc(firestore, 'equipments', this.trieOption.uid)
          await updateDoc(equipmentRef, {
            isLeft: this.trieOption.isLeft - this.quantity,
            numberAffected: this.quantity + this.trieOption.numberAffected
          })

          // UPDATE AGENT INFORMATION
          const agentRef = doc(firestore, 'agents', this.detailsAgent.uid)
          await updateDoc(agentRef, {
            haveEquipment: true,
            numberAffected: this.detailsAgent.numberAffected + this.quantity
          })
          this.closeAdd()
          const newEquipment = {}
          newEquipment.equipmentId = this.trieOption.uid
          newEquipment.quantity = this.quantity
          newEquipment.createdAt = new Date()
          this.$emit('changeEquipment', newEquipment)
          // window.location.reload()
        }
      }
    },
    selectedValue (answer) {
      this.trieOption = answer
      console.log(this.trieOption)
    },
    async getAllEquipments () { // GET ALL EQUIPMENTS LIST THAT IS AVAILABLE
      const q = query(collection(firestore, 'equipments'),
        where('isLeft', '>', 0),
        orderBy('isLeft'),
        orderBy('createdAt', 'desc')
      )
      const querySnapshot = await getDocs(q)
      querySnapshot.forEach((doc) => {
        // console.log(doc.id, ' => ', doc.data())
        this.optionsName.push(doc.data())
      })
    },
    closeAdd () {
      this.$emit('closeAddAffected', false)
    },
    isNumberOnly ($event) {
      const keyCode = ($event.keyCode ? $event.keyCode : $event.which)
      // alert(keyCode)
      if ((keyCode < 42 || keyCode > 57) && keyCode !== 46) { // 46 is dot
        $event.preventDefault()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "src/assets/styles/sass/variables";
  .begin {
    border-top: 4px solid $team_blue;
  }
  .header, .ipt {
    background: $team_gray_popup;
  }
  .cancel-button::v-deep {
    .button-base {
      .page-button-real {
        border: none;
        h4 {
          font-size: 1.25rem;
        }
      }
    }
  }
  .valid-button::v-deep {
    .button-base {
      .page-button-real {
        border-radius: .25rem;
        h4 {
          font-size: 1.25rem;
        }
      }
    }
  }
</style>
