<template>
  <div class="requests">
    <div class="begin">
      <div class="title font-medium uppercase mt-6">{{ $t('requests') }}</div><br>
      <div class="body">
        <div class="header-body">
          <div class="loading" :class="{ 'not_empty': loading === false }">
            <loading
              :active="loading"
              :can-cancel="false"
              :on-cancel="onCancel"
              :is-full-page="fullPage"
              background-color="#FFFFFF"
              color="#324971"
              loader="dots"
            ></loading>
          </div>
          <div
            v-if="nothing"
            class="no-student text-center text-xl text-gray-500 py-32"
          >
            {{ $t('noAddedRequest') }}
          </div>
          <request-lists
            v-for="(item, index) in agentRequests"
            :key="index"
            :agents-requests="item"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from 'vue3-loading-overlay'
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css'
import RequestLists from './request-lists'
export default {
  name: 'requests',
  components: { RequestLists, Loading },
  props: {
    agentRequests: Array
  },
  data () {
    return {
      fullPage: false,
      loading: true,
      nothing: false
    }
  },
  created () {
    if (this.agentRequests.length === 0) {
      this.loading = false
      this.nothing = true
    } else {
      this.loading = false
      this.nothing = false
    }
  },
  methods: {
    onCancel () {
      console.log('User cancelled the loader.')
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "src/assets/styles/sass/variables";
  .title {
    color: $team_blue;
  }
  .loading {
    position: relative;
    height: 10vh;
  }
  .not_empty {
    height: auto;
  }
</style>
