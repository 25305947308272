<template>
  <div class="current-hands">
    <div class="begin">
      <div class="title font-medium uppercase">{{ $t('currentHands') }}</div><br>
      <div class="body">
        <div class="header-table flex items-center p-4 bg-white font-medium text-lg">
          <div class="action w-1/12">N°</div>
          <div class="action w-2/5 mr-4">{{ $t('event') }}</div>
          <div class="action w-2/5 mr-4">{{ $t('sitesMenu') }}</div>
          <div class="action w-1/3 mr-4">{{ $t('dateAndHour') }}</div>
        </div>
        <div class="header-body">
          <div class="loading" :class="{ 'not_empty': loading === false }">
            <loading
              :active="loading"
              :can-cancel="false"
              :on-cancel="onCancel"
              :is-full-page="fullPage"
              background-color="#FFFFFF"
              color="#324971"
              loader="dots"
            ></loading>
          </div>
          <div
            v-if="nothing"
            class="no-student text-center text-xl text-gray-500 py-32"
          >
            {{ $t('noCurrentHandDeclared') }}
          </div>
          <current-hands-lists
            v-for="(item, index) in allCurrentHands"
            :key="index"
            :current-hands-list="item"
            :current-hand-id="index"
            :agent-name="agentsDetails.name"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CurrentHandsLists from './current-hands-lists'
import { collection, query, where, getDocs, orderBy } from 'firebase/firestore'
import { firestore } from '../../../main'
import Loading from 'vue3-loading-overlay'
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css'
export default {
  name: 'current-hands',
  components: { Loading, CurrentHandsLists },
  props: {
    agentsDetails: Object
  },
  data () {
    return {
      allCurrentHands: [],
      loading: false,
      fullPage: false,
      nothing: false
    }
  },
  created () {
    this.getAgentCurrentHands()
    // console.log(this.allCurrentHands)
  },
  methods: {
    async getAgentCurrentHands () { // GET AGENT ALL CURRENT HANDS
      const q = query(collection(firestore, 'courant_hands'),
        where('addedBy', '==', this.agentsDetails.uid),
        orderBy('createdAt', 'desc')
      )
      const querySnapshot = await getDocs(q)
      querySnapshot.forEach((doc) => {
        // console.log(doc.id, ' => ', doc.data())
        this.allCurrentHands.push(doc.data())
        if (this.allCurrentHands.length === 0) {
          this.loader = false
          this.nothing = true
        } else {
          this.loader = false
          this.nothing = false
        }
      })
    },
    onCancel () {
      console.log('User cancelled the loader.')
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "src/assets/styles/sass/variables";
  .title {
    color: $team_blue;
  }
  .action {
    color: $team_blue;
  }
  .loading {
    position: relative;
    height: 10vh;
  }
  .not_empty {
    height: auto;
  }
</style>
