<template>
  <div class="add-identification">
    <div class="begin">
      <div class="form w-full">
        <div class="form-bloc flex items-center w-full">
          <div class="login-form mr-3 w-1/3">
            <div class="label mb-2">{{ $t('completeName') }}*</div>
            <div class="input-bloc">
              <input
                v-model="name"
                type="text"
                placeholder="Gildas AMETEPE"
                class="ipt p-4 w-full border rounded"
                @keyup="sendName"
              >
            </div>
          </div>
          <div class="login-form mx-3 w-1/3">
            <div class="label mb-2">{{ $t('birthdayDate') }}</div>
            <div class="input-bloc">
              <v-date-picker class="inline-block birth h-full w-full z-50" :max-date="new Date()" v-model="birthday" @keyup="sendBirthday" @click="sendBirthday">
                <template v-slot="{ inputValue, inputEvents, togglePopover }">
                  <div class="flex items-center">
                    <input
                      :value="inputValue"
                      placeholder="12/08/1993"
                      class="ipt w-full p-4 appearance-none border border-r-0 rounded-l focus:outline-none"
                      v-on="inputEvents"
                    />
                    <button
                      class="p-4 border border-l-0 rounded-r focus:outline-none"
                      @click="togglePopover()"
                    >
                      <icon :data="icons.calendar" original width="15" height="15"/>
                    </button>
                  </div>
                </template>
              </v-date-picker>
            </div>
          </div>
          <div class="login-form ml-3 w-1/3">
            <div class="label mb-2">{{ $t('phone') }}*</div>
            <div class="input-bloc">
              <input
                v-model="phone"
                type="number"
                placeholder="98427565"
                class="ipt p-4 w-full border rounded"
                @keypress="isNumberOnly"
                @keyup="sendPhone"
              >
            </div>
          </div>
        </div><br>
        <div class="form-bloc flex items-center w-full">
          <div class="login-form mr-3 w-1/3">
            <div class="label mb-2">{{ $t('email') }}</div>
            <div class="input-bloc">
              <input
                v-model="email"
                type="email"
                placeholder="gildas.amatepe@gmail.com"
                class="ipt p-4 w-full border rounded"
                @keyup="sendEmail"
              >
            </div>
          </div>
          <div class="login-form mx-3 w-1/3">
            <div class="label mb-2">{{ $t('city') }}*</div>
            <div class="input-bloc relative">
              <input
                v-model="city"
                type="text"
                placeholder="Cotonou"
                class="ipt p-4 w-full border rounded"
                @input="onChange"
              >
              <div
                v-show="isTownList"
                class="countries absolute bg-white z-50 cursor-pointer max-h-64 overflow-y-scroll border w-full"
              >
                <div
                  v-for="(result, i) in filterAgent"
                  :key="i"
                  @click="setResult(i)"
                  class="select-agent px-4 py-2 text-lg"
                >
                  {{ result }}
                </div>
              </div>
            </div>
          </div>
          <div class="login-form ml-3 w-1/3">
            <div class="label mb-2">{{ $t('address') }}*</div>
            <div class="input-bloc">
              <input
                v-model="address"
                type="text"
                placeholder="Akpakpa, Rue Tundé"
                class="ipt p-4 w-full border rounded"
                @keyup="sendAddress"
              >
            </div>
          </div>
        </div><br>
        <div class="form-bloc flex items-end w-full">
          <div class="login-form mr-3 w-1/3">
            <div class="label mb-2">{{ $t('birthdayAct') + ' (pdf, image, Max: 5Mo)' }}</div>
            <div class="input-bloc relative" @click="chooseBirthdayAct">
              <div class="absolute flex border border-transparent left-0 top-0 h-full w-12 cursor-pointer">
                <div class="icon z-0 flex items-center justify-center rounded-tl-xl rounded-bl-xl  h-full w-full">
                  <icon :data="icons.attach" original width="25" height="25"/>
                </div>
              </div>
              <div class="ipt file py-6 pl-12 pr-4 w-full border border-dashed rounded">
                <span v-if="birthdayAct !== ''">{{ truncateString(birthdayAct.name, 35) }}</span>
              </div>
            </div>
            <div v-if="birthdayActError" class="error text-red-500 mt-4">
              {{ $t('fileError') }}
            </div>
          </div>
          <div class="login-form mx-3 w-1/3">
            <div class="label mb-2">{{ $t('identityCard') + ' (pdf, image, Max: 5Mo)' }}</div>
            <div class="input-bloc relative" @click="chooseIdentityCard">
              <div class="absolute flex border border-transparent left-0 top-0 h-full w-12 cursor-pointer">
                <div class="icon z-0 flex items-center justify-center rounded-tl-xl rounded-bl-xl  h-full w-full">
                  <icon :data="icons.attach" original width="25" height="25"/>
                </div>
              </div>
              <div class="ipt file py-6 pl-12 pr-4 w-full border border-dashed rounded">
                <span v-if="identityCard !== ''">{{ truncateString(identityCard.name, 35) }}</span>
              </div>
            </div>
            <div v-if="identityCardError" class="error text-red-500 mt-4">
              {{ $t('fileError') }}
            </div>
          </div>
          <div class="login-form ml-3 w-1/3">
            <div class="label mb-2">{{ $t('identityPicture') + ' (pdf, image, Max: 5Mo)' }}</div>
            <div class="input-bloc relative" @click="choosePicture">
              <div class="absolute flex border border-transparent left-0 top-0 h-full w-12 cursor-pointer">
                <div class="icon z-0 flex items-center justify-center rounded-tl-xl rounded-bl-xl  h-full w-full">
                  <icon :data="icons.attach" original width="25" height="25"/>
                </div>
              </div>
              <div class="ipt file py-6 pl-12 pr-4 w-full border border-dashed rounded">
                <span v-if="identityPicture !== ''">{{ truncateString(identityPicture.name, 35) }}</span>
              </div>
            </div>
            <div v-if="identityPictureError" class="error text-red-500 mt-4">
              {{ $t('fileError') }}
            </div>
          </div>
        </div><br>
        <div class="form-bloc flex items-end w-full">
          <div class="login-form mr-3 w-1/3">
            <div class="label mb-2">{{ $t('residenceAttestation') + ' (pdf, image, Max: 5Mo)' }}</div>
            <div class="input-bloc relative" @click="chooseResidenceAttestation">
              <div class="absolute flex border border-transparent left-0 top-0 h-full w-12 cursor-pointer">
                <div class="icon z-0 flex items-center justify-center rounded-tl-xl rounded-bl-xl  h-full w-full">
                  <icon :data="icons.attach" original width="25" height="25"/>
                </div>
              </div>
              <div class="ipt file py-6 pl-12 pr-4 w-full border border-dashed rounded">
                <span v-if="residenceAttestation !== ''">{{ truncateString(residenceAttestation.name, 35) }}</span>
              </div>
            </div>
            <div v-if="residenceAttestationError" class="error text-red-500 mt-4">
              {{ $t('fileError') }}
            </div>
          </div>
          <div class="login-form mx-3 w-1/3">
            <div class="label mb-2">{{ $t('childrenBirthdayAct') + ' (pdf, image, Max: 5Mo)' }}</div>
            <div class="input-bloc relative" @click="chooseChildrenBirthdayAct">
              <div class="absolute flex border border-transparent left-0 top-0 h-full w-12 cursor-pointer">
                <div class="icon z-0 flex items-center justify-center rounded-tl-xl rounded-bl-xl  h-full w-full">
                  <icon :data="icons.attach" original width="25" height="25"/>
                </div>
              </div>
              <div class="ipt file py-6 pl-12 pr-4 w-full border border-dashed rounded">
                <span v-if="childrenBirthdayAct !== ''">{{ truncateString(childrenBirthdayAct.name, 35) }}</span>
              </div>
            </div>
            <div v-if="childrenBirthdayActError" class="error text-red-500 mt-4">
              {{ $t('fileError') }}
            </div>
          </div>
          <div class="login-form ml-3 w-1/3">
            <div class="label mb-2">{{ $t('wifeBirthdayAct') + ' (pdf, image, Max: 5Mo)' }}</div>
            <div class="input-bloc relative" @click="chooseWifeBirthdayAct">
              <div class="absolute flex border border-transparent left-0 top-0 h-full w-12 cursor-pointer">
                <div class="icon z-0 flex items-center justify-center rounded-tl-xl rounded-bl-xl  h-full w-full">
                  <icon :data="icons.attach" original width="25" height="25"/>
                </div>
              </div>
              <div class="ipt file py-6 pl-12 pr-4 w-full border border-dashed rounded">
                <span v-if="wifeBirthdayAct !== ''">{{ truncateString(wifeBirthdayAct.name, 35) }}</span>
              </div>
            </div>
            <div v-if="wifeBirthdayActError" class="error text-red-500 mt-4">
              {{ $t('fileError') }}
            </div>
          </div>
        </div><br>
        <div class="form-bloc flex items-end w-full">
          <div class="login-form mr-3 w-1/3">
            <div class="label mb-2">{{ $t('weddingAct') + ' (pdf, image, Max: 5Mo)' }}</div>
            <div class="input-bloc relative" @click="chooseWeddingAct">
              <div class="absolute flex border border-transparent left-0 top-0 h-full w-12 cursor-pointer">
                <div class="icon z-0 flex items-center justify-center rounded-tl-xl rounded-bl-xl  h-full w-full">
                  <icon :data="icons.attach" original width="25" height="25"/>
                </div>
              </div>
              <div class="ipt file py-6 pl-12 pr-4 w-full border border-dashed rounded">
                <span v-if="weddingAct !== ''">{{ truncateString(weddingAct.name, 35) }}</span>
              </div>
            </div>
            <div v-if="weddingActError" class="error text-red-500 mt-4">
              {{ $t('fileError') }}
            </div>
          </div>
          <div class="login-form mx-3 w-1/3">
            <div class="label mb-2">{{ $t('recommendationLetter') + ' (pdf, image, Max: 5Mo)' }}</div>
            <div class="input-bloc relative" @click="chooseRecommendationLetter">
              <div class="absolute flex border border-transparent left-0 top-0 h-full w-12 cursor-pointer">
                <div class="icon z-0 flex items-center justify-center rounded-tl-xl rounded-bl-xl  h-full w-full">
                  <icon :data="icons.attach" original width="25" height="25"/>
                </div>
              </div>
              <div class="ipt file py-6 pl-12 pr-4 w-full border border-dashed rounded">
                <span v-if="recommendationLetter !== ''">{{ truncateString(recommendationLetter.name, 35) }}</span>
              </div>
            </div>
            <div v-if="recommendationLetterError" class="error text-red-500 mt-4">
              {{ $t('fileError') }}
            </div>
          </div>
          <div class="login-form ml-3 w-1/3">
            <div class="label mb-2">{{ $t('criminalRecord') + ' (pdf, image, Max: 5Mo)' }}</div>
            <div class="input-bloc relative" @click="chooseCriminalRecord">
              <div class="absolute flex border border-transparent left-0 top-0 h-full w-12 cursor-pointer">
                <div class="icon z-0 flex items-center justify-center rounded-tl-xl rounded-bl-xl  h-full w-full">
                  <icon :data="icons.attach" original width="25" height="25"/>
                </div>
              </div>
              <div class="ipt file py-6 pl-12 pr-4 w-full border border-dashed rounded">
                <span v-if="criminalRecord !== ''">{{ truncateString(criminalRecord.name, 35) }}</span>
              </div>
            </div>
            <div v-if="criminalRecordError" class="error text-red-500 mt-4">
              {{ $t('fileError') }}
            </div>
          </div>
        </div><br>
      </div>
    </div>
  </div>
</template>

<script>
import attach from '../../../assets/icons/ic_attach.svg'
import calendar from '../../../assets/icons/ic_calendar.svg'
import http from '../../../plugins/https'
import towns from '@/components/helper/add/town-base'
export default {
  name: 'add-identification',
  props: {
    isAgentDetails: Object
  },
  data () {
    return {
      icons: { attach, calendar },
      name: '',
      birthday: new Date(),
      phone: null,
      email: '',
      city: '',
      address: '',
      birthdayAct: '',
      birthdayActFile: '',
      birthdayActError: false,
      identityCard: '',
      identityCardFile: '',
      identityCardError: false,
      identityPicture: '',
      identityPictureFile: '',
      identityPictureError: false,
      residenceAttestation: '',
      residenceAttestationFile: '',
      residenceAttestationError: false,
      childrenBirthdayAct: '',
      childrenBirthdayActFile: '',
      childrenBirthdayActError: false,
      wifeBirthdayAct: '',
      wifeBirthdayActFile: '',
      wifeBirthdayActError: false,
      weddingAct: '',
      weddingActFile: '',
      weddingActError: false,
      recommendationLetter: '',
      recommendationLetterFile: '',
      recommendationLetterError: false,
      criminalRecord: '',
      criminalRecordFile: '',
      criminalRecordError: false,
      allTown: towns,
      isTownList: false
    }
  },
  created () {
    // this.getAllTown()
  },
  mounted () {
    if (Object.keys(this.isAgentDetails).length !== 0) {
      this.name = this.isAgentDetails.name
      this.birthday = new Date(this.isAgentDetails.birthday)
      this.phone = this.isAgentDetails.phone
      this.email = this.isAgentDetails.email
      this.city = this.isAgentDetails.city
      this.address = this.isAgentDetails.address
      // this.birthdayActFile = this.isAgentDetails.birthdayAct
      // this.identityCardFile = this.isAgentDetails.identityCard
      // this.identityPictureFile = this.isAgentDetails.identityPicture
      // this.residenceAttestationFile = this.isAgentDetails.residenceAttestation
      // this.childrenBirthdayActFile = this.isAgentDetails.childrenBirthdayAct
      // this.wifeBirthdayActFile = this.isAgentDetails.wifeBirthdayAct
      // this.weddingActFile = this.isAgentDetails.weddingAct
      // this.recommendationLetterFile = this.isAgentDetails.recommendationLetter
      // this.criminalRecordFile = this.isAgentDetails.criminalRecord

      this.$emit('sendCity', this.city)
      this.$emit('sendName', this.name)
      this.$emit('sendBirthday', this.birthday)
      this.$emit('sendPhone', this.phone)
      this.$emit('sendEmail', this.email)
      this.$emit('sendAddress', this.address)
      this.$emit('sendBirthdayAct', this.birthdayAct)
      this.$emit('sendIdentityPicture', this.identityPicture)
      this.$emit('sendIdentityCard', this.identityCard)
      this.$emit('sendResidenceAttestation', this.residenceAttestation)
      this.$emit('sendChildrenBirthdayAct', this.childrenBirthdayAct)
      this.$emit('sendWifeBirthdayAct', this.wifeBirthdayAct)
      this.$emit('sendWeddingAct', this.weddingAct)
      this.$emit('sendRecommendationLetter', this.recommendationLetter)
      this.$emit('sendCriminalRecord', this.criminalRecord)
    }
  },
  computed: {
    filterAgent: function () {
      let back = this.allTown
      if (this.city !== '') {
        let authorNameSearchString = this.city
        authorNameSearchString = authorNameSearchString.toLowerCase()
        back = back.filter(function (item) {
          if ((item.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase().indexOf(authorNameSearchString.normalize('NFD').replace(/[\u0300-\u036f]/g, '')) !== -1)) {
            // console.log(item)
            return item
          }
        })
      }
      return back
    }
  },
  methods: {
    onChange () {
      this.isTownList = true
    },
    setResult (index) { // SET SEARCH AGENT RESULT
      if (this.city === '') {
        this.isTownList = false
      } else {
        this.city = this.filterAgent[index]
        // console.log(this.city)
        this.$emit('sendCity', this.city)
        this.isTownList = false
      }
    },
    getAllTown () { // GET BENIN ALl TOWN LIST
      http.get('https://bj-decoupage-territorial.herokuapp.com/api/v1/towns')
        .then(response => {
          // console.log(response.towns)
          this.allTown = response.towns
        }).catch(error => {
          console.log(error)
        })
    },
    sendName () {
      this.$emit('sendName', this.name)
    },
    sendBirthday () {
      this.$emit('sendBirthday', this.birthday)
    },
    sendPhone () {
      this.$emit('sendPhone', this.phone)
    },
    sendEmail () {
      this.$emit('sendEmail', this.email)
    },
    sendAddress () {
      this.$emit('sendAddress', this.address)
    },
    chooseBirthdayAct () { // CHOOSE BIRTHDAY ACT FILE
      const input = document.createElement('input')
      input.type = 'file'
      input.setAttribute('accept', 'image/*, .pdf')
      input.onchange = e => {
        this.birthdayAct = e.target.files[0]
        const fileSize = input.files[0].size / 1024 / 1024 // in MiB
        console.log(fileSize)
        if (fileSize > 5) {
          this.birthdayActError = true
        } else {
          this.birthdayActError = false
          this.$emit('sendBirthdayAct', this.birthdayAct)
          // this.url = URL.createObjectURL(this.file)
          const reader = new FileReader()
          reader.readAsDataURL(this.birthdayAct)
          reader.onload = a => {
            this.birthdayActFile = reader.result
          }
        }
      }
      input.click()
    },
    chooseIdentityCard () { // CHOOSE IDENTITY CARD PICTURE
      const input = document.createElement('input')
      input.type = 'file'
      input.setAttribute('accept', 'image/*, .pdf')
      input.onchange = e => {
        this.identityCard = e.target.files[0]
        const fileSize = input.files[0].size / 1024 / 1024 // in MiB
        if (fileSize > 5) {
          this.identityCardError = true
        } else {
          this.identityCardError = false
          this.$emit('sendIdentityCard', this.identityCard)
          // this.url = URL.createObjectURL(this.file)
          const reader = new FileReader()
          reader.readAsDataURL(this.identityCard)
          reader.onload = a => {
            this.identityCardFile = reader.result
          }
        }
      }
      input.click()
    },
    choosePicture () { // CHOOSE PROFILE PICTURE
      const input = document.createElement('input')
      input.type = 'file'
      input.setAttribute('accept', 'image/*, .pdf')
      input.onchange = e => {
        this.identityPicture = e.target.files[0]
        const fileSize = input.files[0].size / 1024 / 1024 // in MiB
        if (fileSize > 5) {
          this.identityPictureError = true
        } else {
          this.identityPictureError = false
          this.$emit('sendIdentityPicture', this.identityPicture)
          // this.url = URL.createObjectURL(this.file)
          const reader = new FileReader()
          reader.readAsDataURL(this.identityPicture)
          reader.onload = a => {
            this.identityPictureFile = reader.result
          }
        }
      }
      input.click()
    },
    chooseResidenceAttestation () { // CHOOSE RESIDENCE ATTESTATION FILE
      const input = document.createElement('input')
      input.type = 'file'
      input.setAttribute('accept', 'image/*, .pdf')
      input.onchange = e => {
        this.residenceAttestation = e.target.files[0]
        const fileSize = input.files[0].size / 1024 / 1024 // in MiB
        if (fileSize > 5) {
          this.residenceAttestationError = true
        } else {
          this.residenceAttestationError = false
          this.$emit('sendResidenceAttestation', this.residenceAttestation)
          // this.url = URL.createObjectURL(this.file)
          const reader = new FileReader()
          reader.readAsDataURL(this.residenceAttestation)
          reader.onload = a => {
            this.residenceAttestationFile = reader.result
          }
        }
      }
      input.click()
    },
    chooseChildrenBirthdayAct () { // CHOOSE CHILDREN BIRTHDAY ACT
      const input = document.createElement('input')
      input.type = 'file'
      input.setAttribute('accept', '.image/*, .pdf')
      // input.setAttribute('multiple', '')
      input.onchange = e => {
        this.childrenBirthdayAct = e.target.files[0]
        const fileSize = input.files[0].size / 1024 / 1024 // in MiB
        if (fileSize > 5) {
          this.childrenBirthdayActError = true
        } else {
          this.childrenBirthdayActError = false
          this.$emit('sendChildrenBirthdayAct', this.childrenBirthdayAct)
          // this.url = URL.createObjectURL(this.file)
          const reader = new FileReader()
          reader.readAsDataURL(this.childrenBirthdayAct)
          reader.onload = a => {
            this.childrenBirthdayActFile = reader.result
          }
        }
      }
      input.click()
    },
    chooseWifeBirthdayAct () { // CHOOSE WIFE BIRTHDAY ACT
      const input = document.createElement('input')
      input.type = 'file'
      input.setAttribute('accept', 'image/*, .pdf')
      input.onchange = e => {
        this.wifeBirthdayAct = e.target.files[0]
        const fileSize = input.files[0].size / 1024 / 1024 // in MiB
        if (fileSize > 5) {
          this.wifeBirthdayActError = true
        } else {
          this.wifeBirthdayActError = false
          this.$emit('sendWifeBirthdayAct', this.wifeBirthdayAct)
          // this.url = URL.createObjectURL(this.file)
          const reader = new FileReader()
          reader.readAsDataURL(this.wifeBirthdayAct)
          reader.onload = a => {
            this.wifeBirthdayActFile = reader.result
          }
        }
      }
      input.click()
    },
    chooseWeddingAct () { // CHOOSE WEDDING ACT
      const input = document.createElement('input')
      input.type = 'file'
      input.setAttribute('accept', 'image/*, .pdf')
      input.onchange = e => {
        this.weddingAct = e.target.files[0]
        const fileSize = input.files[0].size / 1024 / 1024 // in MiB
        if (fileSize > 5) {
          this.weddingActError = true
        } else {
          this.weddingActError = false
          this.$emit('sendWeddingAct', this.weddingAct)
          // this.url = URL.createObjectURL(this.file)
          const reader = new FileReader()
          reader.readAsDataURL(this.weddingAct)
          reader.onload = a => {
            this.weddingActFile = reader.result
          }
        }
      }
      input.click()
    },
    chooseRecommendationLetter () { // CHOOSE RECOMMENDATION LETTER
      const input = document.createElement('input')
      input.type = 'file'
      input.setAttribute('accept', 'image/*, .pdf')
      input.onchange = e => {
        this.recommendationLetter = e.target.files[0]
        const fileSize = input.files[0].size / 1024 / 1024 // in MiB
        if (fileSize > 5) {
          this.recommendationLetterError = true
        } else {
          this.recommendationLetterError = false
          this.$emit('sendRecommendationLetter', this.recommendationLetter)
          // this.url = URL.createObjectURL(this.file)
          const reader = new FileReader()
          reader.readAsDataURL(this.recommendationLetter)
          reader.onload = a => {
            this.recommendationLetterFile = reader.result
          }
        }
      }
      input.click()
    },
    chooseCriminalRecord () { // CHOOSE CRIMINAL RECORD
      const input = document.createElement('input')
      input.type = 'file'
      input.setAttribute('accept', 'image/*, .pdf')
      input.onchange = e => {
        this.criminalRecord = e.target.files[0]
        const fileSize = this.criminalRecord.size / 1024 / 1024 // in MiB
        if (fileSize > 5) {
          this.criminalRecordError = true
        } else {
          this.criminalRecordError = false
          this.$emit('sendCriminalRecord', this.criminalRecord)
          // this.url = URL.createObjectURL(this.file)
          const reader = new FileReader()
          reader.readAsDataURL(this.criminalRecord)
          reader.onload = a => {
            this.criminalRecordFile = reader.result
          }
        }
      }
      input.click()
    },
    isNumberOnly ($event) {
      const keyCode = ($event.keyCode ? $event.keyCode : $event.which)
      // alert(keyCode)
      if ((keyCode < 42 || keyCode > 57) && keyCode !== 46) { // 46 is dot
        $event.preventDefault()
      }
    },
    validEmail (email) {
      // eslint-disable-next-line no-useless-escape
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(email)
    },
    truncateString (str, num) {
      if (str.length <= num) {
        return str
      }
      return str.slice(0, num) + '...'
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "src/assets/styles/sass/variables";
  .label {
    color: $team_label;
  }
  .input-bloc::v-deep {
    .vc-container {
      font-family: $font-default !important;
      .vc-day-content {
        font-weight: 400 !important;
      }
    }
  }
  .select-agent:hover {
    background: rgb(243 244 246);
  }
  .file {
    background: $team_gray_popup;
  }
  .icon::v-deep {
    .svg-icon {
      path {
        fill: #909090;
      }
    }
  }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
</style>
