<template>
  <div class="designated-site-info">
    <popup-base>
      <div class="begin bg-white m-auto w-3/5 border">
        <div class="header p-8 flex justify-between">
          <div class="title-bloc">
            <div class="title text-2xl font-medium">{{ $t('affectationInformation') }}</div>
          </div>
          <div class="close cursor-pointer" @click="closeDetails">
            <icon :data="icons.close" original width="25" height="25"/>
          </div>
        </div>
        <div class="body p-8">
          <div class="information">
            <div class="form-content flex items-center">
              <div class="label mr-1">{{ $t('siteName') }}:</div>
              <div class="infos font-medium">{{ affectationInfo.client + ' (' + affectationInfo.name + ')' }}</div>
            </div>
            <br>
            <div class="form-content flex items-center">
              <div class="label mr-1">{{ $t('custodyPeriod') }}:</div>
              <div class="infos font-medium">{{ infos?.duration }}</div>
            </div>
            <br>
            <div class="form-content flex items-center">
              <div class="label mr-1">{{ $t('periodicity') }}:</div>
              <div class="infos font-medium">{{ infos?.periodicity }}</div>
            </div>
            <br>
            <div class="form-content flex items-center">
              <div class="label mr-1">{{ $t('beginningDate') }}:</div>
              <div class="infos font-medium">{{ formatDate(infos?.date) }}</div>
            </div>
            <br>
            <div class="form-content flex items-center">
              <div class="label mr-1">{{ $t('uphillTime') }}:</div>
              <div class="infos font-medium">{{ formatHour(infos?.hour) }}</div>
            </div>
          </div>
        </div>
      </div>
    </popup-base>
  </div>
</template>

<script>
import PopupBase from '@/components/helper/add/popup-base'
import close from '@icon/ic_close.svg'
import { collection, query, where, getDocs } from 'firebase/firestore'
import { firestore } from '@/main'
import moment from 'moment'
export default {
  name: 'designated-site-info',
  components: { PopupBase },
  props: {
    affectationInfo: Object,
    affectedSiteId: String,
    agentId: String
  },
  data () {
    return {
      icons: { close },
      affectation: null,
      infos: null
    }
  },
  created () {
    this.getAffectedInformation()
  },
  methods: {
    formatDate (date) {
      return moment(date).format('L')
    },
    formatHour (hour) {
      return moment(hour).format('LT')
    },
    getAllInfos () {
      for (let i = 0; i < this.affectation.length; i++) {
        if (this.affectation[i].agent === this.agentId) {
          this.infos = this.affectation[i]
        }
      }
    },
    async getAffectedInformation () {
      const q = query(collection(firestore, 'designation'), where('uid', '==', this.affectedSiteId))

      const querySnapshot = await getDocs(q)
      querySnapshot.forEach((doc) => {
        // console.log(doc.id, ' => ', doc.data())
        this.affectation = doc.data().designatedAgents
        this.getAllInfos()
      })
    },
    closeDetails () {
      this.$emit('closeDetailsAffectation', false)
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "src/assets/styles/sass/variables";
  .begin {
    border-top: 4px solid $team_blue;
  }
  .header {
    background: $team_gray_popup;
  }
  .label {
    color: $team_label;
  }
</style>
